import React, { FC, useEffect, useState } from 'react';
import { Button, Form, InputNumber, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { Notification } from 'common/components/Notification';
import { usePropertyContext } from 'common/hooks/usePropertyContext';
import { stringToNumber } from 'common/helpers/common.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { IFormValue } from 'common/models';
import { LIST_LIMIT_0 } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { NewWorkspacePositionTemplates } from 'entities/Workspace/components/NewWorkspacePositionTemplates';
import {
  getWorkspacePositionOptionalPropertiesForView,
  getWorkspacePositionRequiredPropertiesForView,
  mapFormValuesToWorkspacePositionPropertyListWithResult,
} from 'entities/Workspace/Workspace.helper';
import { WorkspacePositionChips } from 'entities/Workspace/components/WorkspacePositionChips';
import { ICategory } from 'entities/Categories/Categories.models';
import {
  getPropertyPresetType,
  propertyRangeResultToRangeValue,
  renderPropertyFormField,
  setPropertyListItemHidden,
} from 'entities/Property/Property.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = (props) => {
  const {
    // state
    categoryList,
    propertyListLoading,
    workspacePositionLoading,
    workspacePositionError,
    // dispatch
    getCatalogList,
    getCategoryList,
    setWorkspacePositionError,
    createWorkspacePosition,
  } = props;

  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [form] = useForm();
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const { selectedCategory, onSelectedCategoryChange, propertiesForView, onPropertiesForViewChange } = usePropertyContext();

  const propertiesAsCategories = propertiesForView.filter((property) => !!property.isCategory);
  const requiredPropertiesForView = getWorkspacePositionRequiredPropertiesForView(propertiesForView);
  const optionalPropertiesForView = getWorkspacePositionOptionalPropertiesForView(propertiesForView);

  const onPresetClose = (id: number) => {
    const newPropertiesForViewWithResult = propertiesForView.map((property) => {
      if (property.id === id) {
        form.resetFields([property.id]);

        return { ...property, result: [] };
      }

      return property;
    });

    const newPropertiesForVieWithHidden = setPropertyListItemHidden(newPropertiesForViewWithResult);

    setWorkspacePositionError(null);
    onPropertiesForViewChange(newPropertiesForVieWithHidden);
  };

  const onClearAllPresetClick = () => {
    const propertyListIds = propertiesForView.filter((property) => !property.isCategory).map((property) => property.id);
    const newPropertiesForViewWithResult = propertiesForView.map((property) => {
      return property.isCategory ? property : { ...property, result: [] };
    });
    const newPropertiesForVieWithHidden = setPropertyListItemHidden(newPropertiesForViewWithResult);

    setWorkspacePositionError(null);
    onPropertiesForViewChange(newPropertiesForVieWithHidden);
    form.resetFields(propertyListIds);
  };

  const onFormValuesChange = (values: IFormValue) => {
    const newPropertiesForViewWithResult = mapFormValuesToWorkspacePositionPropertyListWithResult(values, propertiesForView);
    const newPropertiesForView = setPropertyListItemHidden(newPropertiesForViewWithResult);

    newPropertiesForView.forEach((property) => {
      if (property.hidden) {
        form.resetFields([property.id]);
      }
    });

    setWorkspacePositionError(null);
    onPropertiesForViewChange(newPropertiesForView);
  };

  const onFinish = (values: IFormValue) => {
    if (workspaceId && selectedCategory) {
      const { inventoryLimit } = values;

      createWorkspacePosition({
        workspaceId: stringToNumber(workspaceId),
        categoryId: selectedCategory.id,
        inventoryLimit,
        properties: propertiesForView
          .filter((property) => !!property.result?.length)
          .map((property) => {
            const { isRange } = getPropertyPresetType(property);

            return {
              propertyId: property.id,
              values: !isRange ? property.result : undefined,
              range: isRange ? propertyRangeResultToRangeValue(property.result) : undefined,
            };
          }),
        onSuccess: () => {
          navigate(`/workspace-list/${workspaceId}`);
          showSuccessMessage('Добавлена новая позиция.');
        },
      });
    }
  };

  useEffect(() => {
    const fetch = async () => {
      form.resetFields();
      setWorkspacePositionError(null);

      const response = await getCatalogList({ limit: LIST_LIMIT_0 });
      const mainCatalog = response?.find((catalog) => !catalog.sellerId);

      if (mainCatalog) {
        await getCategoryList({ limit: LIST_LIMIT_0, catalogId: mainCatalog.id, countProperties: true });
      }

      setFetchLoading(false);
    };

    fetch();

    return () => {
      setWorkspacePositionError(null);
    };
  }, []);

  return (
    <Spin
      wrapperClassName="redesign workspace-position__spin"
      spinning={fetchLoading || workspacePositionLoading}
      indicator={<SpinIndicator />}
    >
      <div className="redesign workspace-position">
        <NewWorkspacePositionTemplates
          categoryList={categoryList}
          selectedCategory={selectedCategory}
          onSelectedCategoryChange={(value: ICategory | null) => {
            onSelectedCategoryChange(value);
            onPropertiesForViewChange([]);
            form.resetFields();
          }}
        />

        <div className="workspace-position-params">
          <Spin wrapperClassName="workspace-position__spin" spinning={propertyListLoading} indicator={<SpinIndicator />}>
            <InfiniteScrollContainer>
              <div className="workspace-position-params__body">
                <div className="drawer__title mb-52">2. Уточните параметры позиции</div>

                {selectedCategory && (
                  <WorkspacePositionChips
                    categoryName={selectedCategory.displayName}
                    propertyList={propertiesForView}
                    onClear={onPresetClose}
                    onClearAll={onClearAllPresetClick}
                  />
                )}

                <Form
                  form={form}
                  layout="vertical"
                  requiredMark={false}
                  scrollToFirstError
                  onValuesChange={onFormValuesChange}
                  onFinish={onFinish}
                >
                  <div className="mb-72">
                    <div className="workspace-position__properties">
                      <Form.Item className="mb-0" label="Норма хранения" name="inventoryLimit">
                        <InputNumber suffix="шт" controls={false} />
                      </Form.Item>
                    </div>
                  </div>

                  {!!requiredPropertiesForView.length && (
                    <div className="mb-72">
                      <div className="text-h4 mb-32">Обязательные параметры*</div>

                      <div className="workspace-position__properties">
                        {requiredPropertiesForView.map((property) => renderPropertyFormField(property))}
                      </div>
                    </div>
                  )}

                  {!!optionalPropertiesForView.length && (
                    <div className="mb-72">
                      <div className="text-h4 mb-32">Дополнительные параметры</div>

                      <div className="workspace-position__properties">
                        {optionalPropertiesForView.map((property) => renderPropertyFormField(property))}
                      </div>
                    </div>
                  )}

                  {propertiesAsCategories.map(({ id }) => {
                    return <Form.Item noStyle key={id} name={id} children={<div />} />;
                  })}
                </Form>

                {workspacePositionError && (
                  <div className="mb-32">
                    <Notification type="error" description={workspacePositionError} />
                  </div>
                )}
              </div>

              {selectedCategory && (
                <div className="workspace-position-params__footer mb-72">
                  <Button className="button-lg primary" onClick={form.submit}>
                    Сохранить позицию
                  </Button>
                </div>
              )}
            </InfiniteScrollContainer>
          </Spin>
        </div>
      </div>
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  categoryList: state.categoryListState.data,
  propertyListLoading: state.propertyListState.loading,
  workspacePositionLoading: state.workspacePositionState.loading,
  workspacePositionError: state.workspacePositionState.error,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCatalogList: dispatch.catalogListState.getCatalogList,
  getCategoryList: dispatch.categoryListState.getCategoryList,
  setPropertyList: dispatch.propertyListState.setPropertyList,
  setWorkspacePositionError: dispatch.workspacePositionState.setError,
  createWorkspacePosition: dispatch.workspacePositionState.createWorkspacePosition,
});

export const NewWorkspacePosition = connect(mapState, mapDispatch)(Component);
