import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Image } from 'common/components/Image';
import { Counter } from 'common/components/Counter';
import { BuyerCode } from 'common/components/BuyerCode';
import { EStorageKey } from 'common/const/axios.enum';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { getStorageItem } from 'common/helpers/axios.helper';
import { stringToNumber } from 'common/helpers/common.helper';
import { DEFAULT_GOODS_COUNT } from 'common/config';
import { IBasketGoods, IBasketItem } from 'entities/Basket/Basket.models';
import { IGoods } from 'entities/Goods/Goods.models';
import { IAddress } from 'entities/Address/Address.models';
import { getUserName, getUserRole } from 'entities/User/User.helper';
import { IToken } from 'entities/Auth/Auth.models';

export const getBasketId = (auth: IToken | null) => {
  const { isSellerManager } = getUserRole(auth?.access.roles);
  const xBasketId = getStorageItem<string>(EStorageKey.XBasketId);
  return isSellerManager && xBasketId ? stringToNumber(xBasketId) : auth?.access.basketId;
};

export const activeBasketListDataToPayload = (data?: IBasketItem[]) => data?.map((item) => ({ key: item.id, ...item }));

export const renderActiveBasketListRecords = () => {
  return [
    {
      dataIndex: 'id',
      render: (text) => `№ ${text}`,
    },
    {
      dataIndex: 'accountName',
    },
    {
      dataIndex: 'subdivisionName',
    },
    {
      dataIndex: 'user',
      render: (_, record) => getUserName.fullName(record.lastName, record.firstName, record.patronymic),
    },
    {
      dataIndex: 'email',
    },
    {
      dataIndex: 'phone',
    },
  ] as ColumnsType<IBasketItem>;
};

export const getActiveBasketListFilterOptions = (data?: IBasketItem[]) => {
  const basketsIdsOptions = data?.map((item) => ({ label: `${item.id}`, value: item.id }));
  const basketsUsersOptions = data?.map((item) => ({
    label: getUserName.fullName(item.lastName, item.firstName, item.patronymic),
    value: item.id,
  }));

  return { basketsIdsOptions, basketsUsersOptions };
};

export const getBasketProgressColor = (percent: number) => {
  if (percent >= 95) {
    return '#EC6665';
  } else if (percent >= 60) {
    return '#F6C543';
  } else {
    return '#69A443';
  }
};

export const renderBasketRecords = (onCountChange: (id: number, value: number) => void) => {
  return [
    {
      key: 'image',
      render: (_, { image }) => <Image size={104} src={image} />,
      width: 144,
    },
    {
      key: 'name',
      render: (_, { name, sellerCode, buyerCode }) => {
        return (
          <div>
            <span className="text-h4-item-name basket__goods-list-item-name">{name}</span>

            <div>
              {sellerCode && (
                <span className="text-tag color-dark-grey" style={{ marginRight: 20 }}>
                  {sellerCode}
                </span>
              )}

              <BuyerCode code={buyerCode} />
            </div>
          </div>
        );
      },
    },
    {
      key: 'price',
      render: (_, { count, price }) => {
        return (
          <div>
            <span className="text-accent basket__goods-list-item-price">{priceFormatter(count * price)}</span>

            {count > DEFAULT_GOODS_COUNT && <span className="text-rag color-dark-grey">{`${priceFormatter(price)}/шт`}</span>}
          </div>
        );
      },
    },
    {
      key: 'count',
      render: (_, { goodId, count }) => {
        return <Counter count={count} responsive onChange={(value) => onCountChange(goodId, value)} />;
      },
      onCell: () => ({ style: { textAlign: 'right' } }),
    },
  ] as ColumnsType<IBasketGoods>;
};

export const getBasketGoodsPayload = (list: IBasketGoods[]) =>
  list.map((goods) => ({ goodId: goods.goodId, count: goods.count }));

export const filterBasketGoodsList = {
  byId: (list: IBasketGoods[], id: number) => list.filter((goods) => goods.goodId === id),
  notEqualId: (list: IBasketGoods[], id: number) => list.filter((goods) => goods.goodId !== id),
  byMaxGoodPrice: (list: IBasketGoods[], maxGoodPrice?: number) => {
    if (maxGoodPrice !== undefined) {
      return list.filter((goods) => goods.price < maxGoodPrice && goods.isAvailableForCustomer);
    }

    return list.filter((goods) => goods.isAvailableForCustomer);
  },
};

export const getBasketGoodsListDataSource = (list: IBasketGoods[]) => list.map((goods) => ({ key: goods.goodId, ...goods }));

export const getBasketGoodsListRowKeys = (list: IBasketGoods[]) => list.map((goods) => goods.goodId);

export const getBasketRequestCompositionInfo = (list: IBasketGoods[], availableLimit?: number) => {
  const count = list.reduce((acc, goods) => acc + goods.count, 0);
  const amount = list.reduce((acc, goods) => acc + goods.count * goods.price, 0);
  const amountWithTaxes = list.reduce((acc, goods) => acc + goods.count * goods.priceWithTaxes, 0);
  const availableAmount = availableLimit ? availableLimit - amount : 0;

  return { count, amount, amountWithTaxes, availableAmount, limitExceeded: availableLimit ? availableAmount < 0 : false };
};

export const goodsToBasketGoods = (goods: IGoods) => ({
  goodId: goods.id,
  count: DEFAULT_GOODS_COUNT,
  brand: goods.brand,
  image: goods.image,
  price: goods.price,
  priceWithTaxes: goods.priceWithTaxes,
  name: goods.name,
  externalId: goods.externalId,
  buyerCode: goods.buyerCode,
  sellerCode: goods.sellerCode,
  properties: goods.properties,
  remains: goods.remains,
  tnvd: goods.tnvd,
  isAvailableForCustomer: goods.isAvailableForCustomer,
});

export const renderBasketRequestCompositionRecords = () => [
  {
    key: 'label',
    dataIndex: 'label',
    width: 208,
  },
  {
    key: 'content',
    dataIndex: 'content',
  },
];

export const getBasketAddressOptions = (list: IAddress[], selectedAddressId: number | null) => {
  return list.map((address) => ({
    label: (
      <div>
        <div className={`${address.id === selectedAddressId ? 'text-h4-item-name' : 'text-lists'}`}>{address.name}</div>

        {address.isMain && (
          <div className="text-tag color-dark-grey" style={{ marginTop: 8 }}>
            Основной адрес
          </div>
        )}
      </div>
    ),
    value: address.id,
    desc: address.name,
  }));
};
