import { EStorageKey } from 'common/const/axios.enum';
import { EColor } from 'common/const/color.enum';
import { ERoute } from 'common/const/route.enum';
import { EUserFrameRole, EUserRole, EUserRoleLabel } from 'common/const/user.enum';
import { getStorageItem } from 'common/helpers/axios.helper';
import { IUser, IUserFrameItem } from 'entities/User/User.models';

export const getUsersFrameRoleOptions = (isAccountOwner?: boolean, isSeller?: boolean) => {
  return Object.values(EUserFrameRole).map((role) => ({
    label: EUserRoleLabel[role],
    value: role,
    disabled:
      role === EUserFrameRole.User ||
      (role === EUserFrameRole.AccountAdmin && isAccountOwner) ||
      (role === EUserFrameRole.SellerManager && !isSeller) ||
      (role === EUserFrameRole.Manager && isSeller),
  }));
};

export const getUserName = {
  initials: (lastName?: string, firstName?: string) => {
    return `${firstName ? firstName[0] : ''}${lastName ? lastName[0] : ''}`;
  },
  fullName: (lastName?: string, firstName?: string, patronymic?: string) => {
    return `${lastName ? lastName : ''} ${firstName ? firstName : ''} ${patronymic ? patronymic : ''}`.trim();
  },
  fullLastNameAndInitials: (lastName?: string, firstName?: string, patronymic?: string) => {
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    return `${lastName ? lastName : ''} ${firstName ? `${firstName.slice(0, 1)}.` : ''}${
      patronymic ? `${patronymic.slice(0, 1)}.` : ''
    }`.trim();
  },
};

export const getProfileFrames = (navigate: (path: string) => void, subdivisionId?: number, roles?: string[]) => {
  const frames: IUserFrameItem[] = [];
  const xBasketId = getStorageItem<string>(EStorageKey.XBasketId);

  if (!subdivisionId && !roles?.includes(EUserRole.SellerManager)) {
    return [];
  }

  frames.push(
    {
      title: 'Мои заказы',
      description: 'Информация о ранее размещенных заказах',
      onClick: () => navigate(ERoute.RequestsForUser),
    },
    {
      title: 'Активная корзина товаров',
      description: 'Нет незавершенных заказов',
      onClick: () => {
        if (xBasketId) {
          navigate(`/active-basket-list/${xBasketId}`);
        } else {
          navigate(ERoute.Basket);
        }
      },
    },
  );

  if (roles?.includes(EUserRole.Manager)) {
    frames.push({
      title: 'Заявки от сотрудников',
      description: 'Обработка заявок от сотрудников',
      onClick: () => navigate(ERoute.RequestsForManager),
    });
  }

  if (roles?.includes(EUserRole.SellerManager)) {
    frames.push({
      title: 'Заказы клиентов',
      description: 'Активные корзины клиентов и запросы на подбор товаров',
      onClick: () => navigate(ERoute.ActiveBasketList),
    });
  }

  return frames;
};

export const getUserStatusColor = (status: string) => {
  if (status === 'active') {
    return EColor.Blue;
  }

  return EColor.Gray;
};

export const mapUserListToUserOptions = (list: IUser[]) => {
  return list
    .map((user) => ({ label: getUserName.fullName(user.lastName, user.firstName, user.patronymic), value: user.id }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const getUserRole = (roles?: string[]) => {
  return {
    isSuperAdmin: roles?.includes(EUserRole.SuperAdmin),
    isAccountAdmin: roles?.includes(EUserRole.AccountAdmin),
    isSellerManager: roles?.includes(EUserRole.SellerManager),
    isManager: roles?.includes(EUserRole.Manager),
    isHeadOfDepartment: roles?.includes(EUserRole.HeadOfDepartment),
    isUser:
      !roles?.includes(EUserRole.SuperAdmin) &&
      !roles?.includes(EUserRole.AccountAdmin) &&
      !roles?.includes(EUserRole.SellerManager) &&
      !roles?.includes(EUserRole.Manager) &&
      !roles?.includes(EUserRole.HeadOfDepartment),
  };
};

export const getUserRoleChipColor = (role: string) => {
  switch (role) {
    case EUserRole.AccountAdmin: {
      return EColor.PalePurple;
    }
    case EUserRole.SellerManager:
    case EUserRole.Manager: {
      return EColor.PaleGreen;
    }
    case EUserRole.HeadOfDepartment: {
      return EColor.PaleBlue;
    }
    default: {
      return EColor.LightGrey;
    }
  }
};
