import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  IUserAutoSupplyApproveChangePayload,
  IUserChangePasswordPayload,
  IUserDeletePayload,
  IUserListParams,
  IUserParams,
  IUserRemoveSubdivisionPayload,
  IUserUpdateByAdminPayload,
  IUserUpdatePayload,
} from 'entities/User/User.models';
import { userTransport } from 'entities/User/User.transport';

export const userListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getUserList(params: IUserListParams) {
    dispatch.userListState.setLoading(true);

    try {
      const response = await userTransport.getUserList(params);
      dispatch.userListState.setList(response.data);
      dispatch.userListState.setCount(response.count);
      return response.data;
    } catch (error) {
      axiosErrorHandler(error);
      return null;
    } finally {
      dispatch.userListState.setLoading(false);
    }
  },
  async getUserListPart(params: IUserListParams) {
    dispatch.userListState.setLoading(true);

    try {
      const response = await userTransport.getUserList(params);
      dispatch.userListState.setPart(response.data);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.userListState.setLoading(false);
    }
  },
});

export const userStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getCurrentUser(id: number) {
    dispatch.userState.setUserLoading(true);

    try {
      const response = await userTransport.getUserById(id);
      dispatch.userState.setCurrentUser(response);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.userState.setUserLoading(false);
    }
  },
  async getUserById(params: IUserParams) {
    dispatch.userState.setUserLoading(true);

    try {
      const response = await userTransport.getUserById(params.id);
      dispatch.userState.setUser(response);
      dispatch.userState.onSuccess(params);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.userState.setUserLoading(false);
    }
  },
  async updateUser(payload: IUserUpdatePayload) {
    try {
      const response = await userTransport.updateUser(payload);
      dispatch.userState.setUser(response);
    } catch (error) {
      axiosErrorHandler(error);
    }
  },
  async updateUserByAdmin(payload: IUserUpdateByAdminPayload) {
    dispatch.userState.setUserLoading(true);

    try {
      const response = await userTransport.updateUserByAdmin(payload);
      dispatch.userListState.updateUser(response);
      dispatch.subdivisionState.updateUser(response);
      dispatch.userState.setUser(response);
      dispatch.userState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.userState.setUserLoading(false);
    }
  },
  async deleteUser(payload: IUserDeletePayload) {
    dispatch.userState.setUserLoading(true);

    try {
      const response = await userTransport.deleteUser(payload);
      dispatch.userListState.deleteUser(response.id);
      dispatch.userState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.userState.setUserLoading(false);
    }
  },
  async removeUserSubdivision(payload: IUserRemoveSubdivisionPayload) {
    dispatch.userState.setUserLoading(true);

    try {
      const response = await userTransport.removeUserSubdivision(payload);
      dispatch.userState.setUser(response);
      dispatch.userState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.userState.setUserLoading(false);
    }
  },
  async changeUserPassword(payload: IUserChangePasswordPayload) {
    dispatch.userState.setUserLoading(true);

    try {
      const response = await userTransport.changeUserPassword(payload);
      dispatch.userState.setUser(response);
      dispatch.userState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error, dispatch.userState.setUserError);
    } finally {
      dispatch.userState.setUserLoading(false);
    }
  },
  async updateUserAutoSupplyApprove(payload: IUserAutoSupplyApproveChangePayload) {
    try {
      const response = await userTransport.updateUserAutoSupplyApprove(payload);
      dispatch.userState.setUser(response);
      dispatch.userState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    }
  },
});

export const statisticsStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getUserStatistics() {
    dispatch.statisticsState.setStatisticsLoading(true);

    try {
      const response = await userTransport.getUserStatistics();
      dispatch.statisticsState.setStatistics(response);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.statisticsState.setStatisticsLoading(false);
    }
  },
});

export const userInventoryStatisticsStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getUserInventoryStatistics() {
    dispatch.userInventoryStatisticsState.setLoading(true);

    try {
      const response = await userTransport.getUserInventoryStatistics();
      dispatch.userInventoryStatisticsState.setUserInventoryStatistics(response);
      return response;
    } catch (error) {
      axiosErrorHandler(error);
      return null;
    } finally {
      dispatch.userInventoryStatisticsState.setLoading(false);
    }
  },
});
