import React, { FC, useEffect, useState } from 'react';
import { Button, Input, Spin, Switch } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { WorkspaceProvider } from 'common/hooks/useWorkspaceContext';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { Label } from 'common/components/Label';
import { WarningCard } from 'common/components/WarningCard';
import { ERoute } from 'common/const/route.enum';
import { EMode } from 'common/const/common.enum';
import { EColor } from 'common/const/color.enum';
import { stringToNumber } from 'common/helpers/common.helper';
import { showMessage } from 'common/helpers/message.helper';
import { onInputKeyDown } from 'common/helpers/input.helper';
import { usePropertyContext } from 'common/hooks/usePropertyContext';
import { LIST_LIMIT_0 } from 'common/config';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as ArrowLeftLongIcon } from 'app/assets/images/redesign/arrow-left-long.svg';
import { ReactComponent as UserIcon } from 'app/assets/images/redesign/user.svg';
import { ReactComponent as AffiliateIcon } from 'app/assets/images/redesign/affiliate.svg';
import { ReactComponent as InfoRedIcon } from 'app/assets/images/redesign/info-red.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { getUserName } from 'entities/User/User.helper';
import { WorkspacePositionGroup } from 'entities/Workspace/components/WorkspacePositionGroup';
import { WorkspaceTools } from 'entities/Workspace/components/WorkspaceTools';
import { CreateWorkspaceCard } from 'entities/Workspace/components/CreateWorkspaceCard';
import { EmptyWorkspace } from 'entities/Workspace/components/EmptyWorkspace';
import { EditWorkspacePositionCard } from 'entities/Workspace/components/EditWorkspacePositionCard';
import { WorkspacePositionCard } from 'entities/Workspace/components/WorkspacePositionCard';
import { IWorkspaceCatalogCategory } from 'entities/Workspace/Workspace.models';
import { filterWorkspacePositions } from 'entities/Workspace/Workspace.helper';

interface IComponentProps {
  asUser?: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    asUser,
    // state
    propertyListLoading,
    goodsListLoading,
    subdivisionListLoading,
    userListLoading,
    auth,
    currentUser,
    groupedWorkspace,
    groupedWorkspaceLoading,
    workspaceLoading,
    workspacePositionLoading,
    // dispatch
    getSubdivisionList,
    getUserList,
    getGroupedWorkspaceById,
    deleteWorkspace,
  } = props;

  const [search, setSearch] = useState<string>('');
  const [searchForFilter, setSearchForFilter] = useState<string>('');
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [showPositionPresets, setShowPositionPresets] = useState<boolean>(true);
  const [openCreateWorkspaceCard, setOpenCreateWorkspaceCard] = useState<boolean>(false);
  const [openDeleteWorkspaceDrawer, setOpenDeleteWorkspaceDrawer] = useState<boolean>(false);
  const [createWorkspaceCardMode, setCreateWorkspaceCardMode] = useState<EMode>(EMode.Create);
  const [selectedCategory, setSelectedCategory] = useState<IWorkspaceCatalogCategory | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { onSelectedCategoryChange, onPropertiesForViewChange } = usePropertyContext();

  const accountId = auth?.access.accountId;
  const workspaceId = asUser ? currentUser?.workspaceId : id ? stringToNumber(id) : null;
  const isEditableWorkspace = !asUser;
  const filteredGroupedWorkspaceCategories = filterWorkspacePositions(
    searchForFilter,
    groupedWorkspace,
    selectedCategory?.categoryId,
  );

  const onSearch = () => {
    setSelectedCategory(null);
    setSearchForFilter(search);
  };

  const onCreateWorkspaceClick = () => {
    onSelectedCategoryChange(null);
    onPropertiesForViewChange([]);
    navigate(`/new-workspace-position/${groupedWorkspace?.workspace.id}`);
  };

  const onCopyWorkspaceClick = async () => {
    await getSubdivisionList({ limit: LIST_LIMIT_0 });
    await getUserList({ accountId, limit: LIST_LIMIT_0 });
    setCreateWorkspaceCardMode(EMode.Copy);
    setOpenCreateWorkspaceCard(true);
  };

  const onEditWorkspaceClick = async () => {
    await getSubdivisionList({ limit: LIST_LIMIT_0 });
    await getUserList({ accountId, limit: LIST_LIMIT_0 });
    setCreateWorkspaceCardMode(EMode.Edit);
    setOpenCreateWorkspaceCard(true);
  };

  const onDeleteWorkspaceClick = () => {
    setOpenDeleteWorkspaceDrawer(true);
  };

  const onDeleteWorkspaceConfirm = () => {
    if (groupedWorkspace?.workspace) {
      deleteWorkspace({
        id: groupedWorkspace.workspace.id,
        onSuccess: () => {
          setOpenDeleteWorkspaceDrawer(false);
          showMessage({ content: 'Карта оснащения удалена из подразделения.', icon: <BinIcon className="icon-bin-dark-grey" /> });
          navigate(ERoute.WorkspaceList, { replace: true });
        },
      });
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (workspaceId) {
        await getGroupedWorkspaceById(workspaceId);
      }

      setFetchLoading(false);
    };

    fetch();
  }, [workspaceId]);

  return (
    <WorkspaceProvider>
      <Spin
        wrapperClassName="workspace__spin"
        spinning={fetchLoading || workspacePositionLoading || propertyListLoading || goodsListLoading}
        indicator={<SpinIndicator />}
      >
        <div className="redesign workspace">
          <div className="workspace__content">
            <div className="workspace__search">
              {isEditableWorkspace && (
                <Button
                  className="button-circle secondary"
                  icon={<ArrowLeftLongIcon className="arrow-left-long" />}
                  onClick={() => navigate(ERoute.WorkspaceList)}
                />
              )}

              <Input
                placeholder="Поиск по карте оснащения"
                allowClear={{
                  clearIcon: (
                    <CloseIcon
                      className="icon-close-input"
                      onClick={() => {
                        if (searchForFilter.length) {
                          setSelectedCategory(null);
                          setSearchForFilter('');
                        }
                      }}
                    />
                  ),
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => onInputKeyDown(e.key, onSearch)}
              />

              <Button className="button-circle primary" icon={<SearchIcon className="icon-search-white" />} onClick={onSearch} />
            </div>

            <div className="workspace__content-container">
              <div className="text-h1 workspace__title">{groupedWorkspace?.workspace.name}</div>

              {isEditableWorkspace && (
                <div className="workspace__subtitle">
                  {groupedWorkspace?.workspace.user ? (
                    <Label
                      name={getUserName.fullLastNameAndInitials(
                        groupedWorkspace?.workspace.user.lastName,
                        groupedWorkspace?.workspace.user.firstName,
                        groupedWorkspace?.workspace.user.patronymic,
                      )}
                      icon={<UserIcon className="icon-user-bright-green" />}
                      color={EColor.BrightGreen}
                      ellipsis
                    />
                  ) : (
                    <div className="workspace__subtitle-no-user">
                      <div className="workspace__subtitle-no-user-icon">
                        <UserIcon className="icon-user-dark-grey" />
                      </div>

                      <span className="text-h4-item-name color-dark-grey">Сотрудник не назначен</span>

                      <InfoRedIcon className="icon-info-red" />
                    </div>
                  )}

                  {groupedWorkspace?.workspace.subdivision && (
                    <Label
                      name={groupedWorkspace?.workspace.subdivision?.name}
                      icon={<AffiliateIcon className="icon-affiliate-bright-green" />}
                      color={EColor.BrightGreen}
                      ellipsis
                    />
                  )}
                </div>
              )}

              {!!filteredGroupedWorkspaceCategories?.length && (
                <div className="workspace__content-container-toggle">
                  <span className="text-tag">Параметры позиций</span>

                  <Switch checked={showPositionPresets} onChange={setShowPositionPresets} />
                </div>
              )}
            </div>

            <InfiniteScrollContainer>
              <Spin
                wrapperClassName="workspace__spin"
                spinning={groupedWorkspaceLoading || subdivisionListLoading || userListLoading}
                indicator={<SpinIndicator />}
              >
                <div className="workspace__content-container">
                  {filteredGroupedWorkspaceCategories?.map((group) => {
                    return (
                      <WorkspacePositionGroup
                        key={group.categoryId}
                        group={group}
                        editable={isEditableWorkspace}
                        showPresets={showPositionPresets}
                      />
                    );
                  })}

                  <EmptyWorkspace
                    open={!groupedWorkspaceLoading && !filteredGroupedWorkspaceCategories?.length}
                    editable={isEditableWorkspace}
                    onClick={onCreateWorkspaceClick}
                  />
                </div>
              </Spin>
            </InfiniteScrollContainer>
          </div>

          <WorkspaceTools
            editable={isEditableWorkspace}
            catalog={groupedWorkspace?.catalog}
            category={selectedCategory}
            setCategory={(value) => {
              setSearch('');
              setSearchForFilter('');
              setSelectedCategory(value);
            }}
            onCopyClick={onCopyWorkspaceClick}
            onEditClick={onEditWorkspaceClick}
            onDeleteClick={onDeleteWorkspaceClick}
            onCreatePosition={onCreateWorkspaceClick}
          />
        </div>

        <CreateWorkspaceCard
          open={openCreateWorkspaceCard}
          mode={createWorkspaceCardMode}
          workspace={groupedWorkspace?.workspace}
          onOpenChange={setOpenCreateWorkspaceCard}
          onClose={() => {
            setOpenCreateWorkspaceCard(false);
            setCreateWorkspaceCardMode(EMode.Create);
          }}
        />

        <WorkspacePositionCard editable={isEditableWorkspace} />

        <EditWorkspacePositionCard categories={groupedWorkspace?.categories} />

        <WarningCard
          open={openDeleteWorkspaceDrawer}
          content="Вы уверены, что хотите удалить карту оснащения?"
          subtitle="Отменить данное действие будет невозможно."
          confirmBtnTitle="Удалить"
          loading={workspaceLoading}
          onClose={() => setOpenDeleteWorkspaceDrawer(false)}
          onConfirm={onDeleteWorkspaceConfirm}
        />
      </Spin>
    </WorkspaceProvider>
  );
};

const mapState = (state: RootState) => ({
  propertyListLoading: state.propertyListState.loading,
  goodsListLoading: state.goodsListState.loading,
  subdivisionListLoading: state.subdivisionListState.loading,
  userListLoading: state.userListState.loading,
  auth: state.authState.data,
  currentUser: state.userState.currentUser,
  groupedWorkspace: state.groupedWorkspaceState.data,
  groupedWorkspaceLoading: state.groupedWorkspaceState.loading,
  workspaceLoading: state.workspaceState.loading,
  workspacePositionLoading: state.workspacePositionState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSubdivisionList: dispatch.subdivisionListState.getSubdivisionList,
  getUserList: dispatch.userListState.getUserList,
  getGroupedWorkspaceById: dispatch.groupedWorkspaceState.getGroupedWorkspaceById,
  deleteWorkspace: dispatch.workspaceState.deleteWorkspace,
});

export const Workspace = connect(mapState, mapDispatch)(Component);
