export enum EUserRole {
  User = 'user',
  Manager = 'manager',
  SellerManager = 'seller_manager',
  AccountAdmin = 'account_admin',
  SuperAdmin = 'super_admin',
  HeadOfDepartment = 'head_of_department',
}

export enum EUserRoleLabel {
  user = 'Пользователь',
  manager = 'Закупщик',
  seller_manager = 'Аккаунт-менеджер',
  account_admin = 'Администратор аккаунта',
  super_admin = 'Администратор системы',
  head_of_department = 'Руководитель подразделения',
}

export enum EUserStatus {
  Active = 'active',
  NotActive = 'not_active',
}

export enum EUserStatusLabel {
  active = 'Активирован',
  not_active = 'Не активирован',
}

export enum EUserFilterOption {
  NotActive = 'not_active',
  WithoutSubdivision = 'without_subdivision',
}

export enum EUserFilterOptionLabel {
  not_active = 'Не активированы',
  without_subdivision = 'Не привязаны к подразделению',
}

export enum EUserFrameRole {
  User = 'user',
  Manager = 'manager',
  AccountAdmin = 'account_admin',
  SellerManager = 'seller_manager',
}
