import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { EOrderDirection, EOrderField } from 'common/const/common.enum';
import { ESettingsTab } from 'common/const/settings.enum';
import { UserProvider } from 'common/hooks/useUserContext';
import { LegalEntityProvider } from 'common/hooks/useLegalEntityContext';
import { SubdivisionProvider } from 'common/hooks/useSubdivisionContext';
import { LIST_LIMIT_0 } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { AccountSettings } from 'entities/Settings/components/AccountSettings/AccountSettings';
import { SubdivisionSettings } from 'entities/Settings/components/SubdivisionSettings/SubdivisionSettings';
import { SettingsSidebar } from 'entities/Settings/components/SettingsSidebar';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ subdivisionList, getSubdivisionList }) => {
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const { tab } = useParams();

  const isAccountSettings = tab === ESettingsTab.Account;

  useEffect(() => {
    const fetch = async () => {
      await getSubdivisionList({
        orderField: EOrderField.Name,
        orderDirection: EOrderDirection.ASC,
        limit: LIST_LIMIT_0,
      });
      setFetchLoading(false);
    };

    fetch();
  }, []);

  if (fetchLoading) {
    return (
      <div className="redesign settings">
        <Spin indicator={<SpinIndicator />} />
      </div>
    );
  }

  return (
    <UserProvider>
      <LegalEntityProvider>
        <SubdivisionProvider>
          <div className="redesign settings">
            <SettingsSidebar subdivisionList={subdivisionList} />

            {isAccountSettings ? <AccountSettings /> : <SubdivisionSettings subdivisionList={subdivisionList} />}
          </div>
        </SubdivisionProvider>
      </LegalEntityProvider>
    </UserProvider>
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.subdivisionListState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSubdivisionList: dispatch.subdivisionListState.getSubdivisionList,
});

export const Settings = connect(mapState, mapDispatch)(Component);
