import { createModel } from '@rematch/core';
import { DEFAULT_VALUE_0 } from 'common/config';
import { IRootModel } from 'app/store';
import { IBasketListState, IBasketState } from 'entities/Basket/Basket.models';
import { basketListStateReducers, basketStateReducers } from 'entities/Basket/Basket.reducers';
import { basketListStateEffects, basketStateEffects } from 'entities/Basket/Basket.effects';

export const basketListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IBasketListState,
  reducers: basketListStateReducers,
  effects: basketListStateEffects,
});

export const basketState = createModel<IRootModel>()({
  state: {
    data: null,
    currentBasket: null,
    loading: false,
    goodsCount: DEFAULT_VALUE_0,
  } as IBasketState,
  reducers: basketStateReducers,
  effects: basketStateEffects,
});
