import React, { FC } from 'react';
import { Button, Checkbox, Dropdown, Tooltip } from 'antd';
import { EUserStatus } from 'common/const/user.enum';
import { ReactComponent as FiltersIcon } from 'app/assets/images/redesign/filters.svg';
import { IUserListParams } from 'entities/User/User.models';

interface IComponentProps {
  params: IUserListParams;
  onParamsChange: (params: IUserListParams) => void;
}

export const AccountUserListSettingsFilterBtn: FC<IComponentProps> = ({ params, onParamsChange }) => {
  return (
    <Tooltip title="Фильтр" placement="bottom">
      <Dropdown
        overlayClassName="redesign account-user-list-settings-filter-btn__dropdown"
        trigger={['click']}
        placement="bottomRight"
        dropdownRender={() => {
          return (
            <div className="custom-dropdown" style={{ width: 324 }}>
              <Checkbox
                checked={params.status === EUserStatus.NotActive}
                onChange={(e) => onParamsChange({ ...params, status: e.target.checked ? EUserStatus.NotActive : undefined })}
              >
                Не активированы
              </Checkbox>

              <div className="divider mb-8" style={{ marginTop: 8 }} />

              <Checkbox
                checked={params.withoutSubdivision}
                onChange={(e) => onParamsChange({ ...params, withoutSubdivision: e.target.checked ? true : undefined })}
              >
                Не привязаны к подразделению
              </Checkbox>
            </div>
          );
        }}
      >
        <Button
          className={`button-circle secondary ${params.status || params.withoutSubdivision ? 'badge-active' : ''}`}
          icon={<FiltersIcon />}
        />
      </Dropdown>
    </Tooltip>
  );
};
