import { DEFAULT_VALUE_0 } from 'common/config';
import { IBasketListState, IBasket, IBasketState, IBasketItem } from 'entities/Basket/Basket.models';

export const basketListStateReducers = {
  setList: (state: IBasketListState, payload: IBasketItem[]) => ({ ...state, data: payload }),
  setLoading: (state: IBasketListState, payload: boolean) => ({ ...state, loading: payload }),
};

export const basketStateReducers = {
  setBasket: (state: IBasketState, payload: IBasket) => {
    return {
      ...state,
      data: payload,
      currentBasket: state.currentBasket?.id === payload.id ? payload : state.currentBasket,
      goodsCount: payload.goods.length,
    };
  },
  setCurrentBasket: (state: IBasketState, payload: IBasket | null) => {
    return { ...state, currentBasket: payload, goodsCount: payload ? payload.goods.length : DEFAULT_VALUE_0 };
  },
  setLoading: (state: IBasketState, payload: boolean) => ({ ...state, loading: payload }),
};
