import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Select, Table, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { Notification } from 'common/components/Notification';
import { countFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { mapToMenuWithDivider } from 'common/helpers/common.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { EMessage } from 'common/const/message.enum';
import { SELECT_LIST_HEIGHT_320, SELECT_LIST_HEIGHT_334 } from 'common/config';
import { ReactComponent as ExportIcon } from 'app/assets/images/redesign/export.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { RootDispatch, RootState } from 'app/store';
import {
  getBasketAddressOptions,
  getBasketRequestCompositionInfo,
  renderBasketRequestCompositionRecords,
} from 'entities/Basket/Basket.helper';
import { IBasket, IBasketGoods } from 'entities/Basket/Basket.models';

interface IComponentProps {
  basket: IBasket | null;
  isSeller?: boolean;
  isCurrentBasket: boolean;
  selectedGoodsList: IBasketGoods[];
  limitExceeded: boolean;
  loading: boolean;
  updateGoodsList?: (value: IBasketGoods[]) => void;
  onSendClick: () => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const BasketRequestCompositionComponent: FC<AllType> = (props) => {
  const {
    // props
    basket,
    isSeller,
    isCurrentBasket,
    selectedGoodsList,
    limitExceeded,
    loading,
    updateGoodsList,
    onSendClick,
    // state
    addressList,
    legalList,
    // dispatch
    updateBasket,
    uploadBasketExcel,
  } = props;

  const [selectedAddressId, setSelectedAddressId] = useState<number | null>(null);
  const [selectedLegalId, setSelectedLegalId] = useState<number | null>(null);
  const { count, amount, amountWithTaxes } = useMemo(() => {
    return getBasketRequestCompositionInfo(selectedGoodsList);
  }, [selectedGoodsList]);

  const addressOptions = getBasketAddressOptions(addressList, selectedAddressId);
  const legalOptions = legalList.map((legal) => ({ label: legal.name, value: legal.id }));

  const onAddressChange = async (id: number) => {
    setSelectedAddressId(id);

    if (basket) {
      const response = await updateBasket({
        id: basket.id,
        addressId: id,
        onSuccess: () => {
          showSuccessMessage(EMessage.DeliveryAddressSuccessfullyChanged);
        },
      });

      if (response) {
        updateGoodsList?.(response.goods);
      }
    }
  };

  const onLegalChange = (id: number) => {
    setSelectedLegalId(id);

    if (basket) {
      updateBasket({
        id: basket.id,
        legalId: id,
        onSuccess: () => {
          showSuccessMessage(EMessage.LegalSuccessfullyChanged);
        },
      });
    }
  };

  useEffect(() => {
    if (basket) {
      const { addressId, legalId } = basket;

      if (addressId) {
        setSelectedAddressId(addressId);
      }

      if (legalId) {
        setSelectedLegalId(legalId);
      }
    }
  }, [basket]);

  if (!basket) {
    return null;
  }

  return (
    <div className="redesign basket-request-composition">
      <div className="basket-request-composition__header">
        {!isSeller && (
          <Tooltip title="Экспорт в Excel">
            <Button className="button-circle secondary" icon={<ExportIcon />} onClick={() => uploadBasketExcel(basket.id)} />
          </Tooltip>
        )}
      </div>

      <div className="basket-request-composition__body">
        <div className="basket-request-composition__body-container">
          <span className="text-h1 basket-request-composition__body-title">Состав заявки</span>

          <div className="basket-request-composition__body-block">
            <span className="text-h4 basket-request-composition__body-block-title">Детали доставки</span>

            {!isSeller ? (
              <Table
                className="basket-request-composition__body-block-table selectable"
                pagination={false}
                showHeader={false}
                size="small"
                dataSource={[
                  {
                    key: 'address',
                    label: <span className="text-body color-dark-grey">Адрес:</span>,
                    content: (
                      <Select
                        rootClassName="redesign"
                        className="basket-request-composition__body-block-select"
                        popupClassName="basket-request-composition__body-block-select-popup"
                        options={mapToMenuWithDivider(addressOptions)}
                        value={selectedAddressId}
                        onChange={onAddressChange}
                        suffixIcon={<ArrowDownShortIcon className="icon-arrow-down-short-dark-grey" />}
                        listHeight={SELECT_LIST_HEIGHT_334}
                        virtual={false}
                        popupMatchSelectWidth={false}
                        optionLabelProp="desc"
                        placeholder={EPlaceholder.SelectAddress}
                        notFoundContent={<span className="text-lists color-dark-grey">В списке нет вариантов.</span>}
                      />
                    ),
                  },
                  {
                    key: 'legal',
                    label: <span className="text-body color-dark-grey">Юридическое лицо:</span>,
                    content: (
                      <Select
                        rootClassName="redesign"
                        className="basket-request-composition__body-block-select"
                        popupClassName="basket-request-composition__body-block-select-popup"
                        options={mapToMenuWithDivider(legalOptions)}
                        value={selectedLegalId}
                        onChange={onLegalChange}
                        suffixIcon={<ArrowDownShortIcon className="icon-arrow-down-short-dark-grey" />}
                        listHeight={SELECT_LIST_HEIGHT_320}
                        virtual={false}
                        popupMatchSelectWidth={false}
                        placeholder={EPlaceholder.SelectLegalEntity}
                        notFoundContent={<span className="text-lists color-dark-grey">В списке нет вариантов.</span>}
                      />
                    ),
                  },
                ]}
                columns={renderBasketRequestCompositionRecords()}
              />
            ) : (
              <div className="basket-request-composition__body-block-container">
                <div className="basket-request-composition__body-block-container-row">
                  <span className="text-body color-dark-grey">Адрес:</span>

                  <span className="text-body">{basket.address}</span>
                </div>

                <div className="basket-request-composition__body-block-container-row">
                  <span className="text-body color-dark-grey">Юридическое лицо:</span>

                  <span className="text-body">{basket.legalName}</span>
                </div>
              </div>
            )}
          </div>

          <div className="basket-request-composition__body-block">
            <span className="text-h4 basket-request-composition__body-block-title">Информация о заявке</span>

            <div className="basket-request-composition__body-block-container">
              <div className="basket-request-composition__body-block-container-row">
                <span className="text-body color-dark-grey">Товаров в заявке:</span>

                <span className="text-body">{countFormatter(count)}</span>
              </div>

              <div className="basket-request-composition__body-block-container-row">
                <span className="text-body color-dark-grey">Стоимость товаров:</span>

                <span className="text-body">{priceFormatter(amount)}</span>
              </div>

              <div className="basket-request-composition__body-block-container-row">
                <span className="text-body color-dark-grey">Сумма НДС:</span>

                <span className="text-body">{priceFormatter(amountWithTaxes - amount)}</span>
              </div>

              <div className="basket-request-composition__body-block-container-row">
                <span className={`text-body ${limitExceeded ? 'color-red' : 'color-dark-grey'}`}>Итого с НДС:</span>

                <span className={`text-body ${limitExceeded ? 'color-red' : ''}`}>{priceFormatter(amountWithTaxes)}</span>
              </div>
            </div>
          </div>

          {limitExceeded && isCurrentBasket && (
            <Notification
              type="error"
              title="Ваша заявка превышает доступный лимит на покупки"
              description="Сократите количество товаров в списке или замените их на позиции с более низкой ценой."
            />
          )}
        </div>

        {!limitExceeded && isCurrentBasket && (
          <div className="basket-request-composition__body-footer">
            <Button className="button-lg primary" onClick={onSendClick} loading={loading} disabled={!selectedGoodsList.length}>
              Отправить
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  addressList: state.addressListState.data,
  legalList: state.legalEntityListState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateBasket: dispatch.basketState.updateBasket,
  uploadBasketExcel: dispatch.basketState.uploadBasketExcel,
});

export const BasketRequestComposition = connect(mapState, mapDispatch)(BasketRequestCompositionComponent);
