import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Dropdown, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { WarningCard } from 'common/components/WarningCard';
import { Label } from 'common/components/Label';
import { EFormFieldMessage } from 'common/const/form.enum';
import { EMode } from 'common/const/common.enum';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { EColor } from 'common/const/color.enum';
import { rules } from 'common/helpers/form.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { useLegalEntityContext } from 'common/hooks/useLegalEntityContext';
import { LEGAL_INN_LENGTH, LEGAL_KPP_LENGTH } from 'common/config';
import { IFormValue } from 'common/models';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as ContractIcon } from 'app/assets/images/redesign/contract.svg';
import { ReactComponent as OptionsIcon } from 'app/assets/images/redesign/options.svg';
import { RootDispatch, RootState } from 'app/store';
import {
  ILegalEntityCreatePayload,
  ILegalEntityCustomer,
  ILegalEntityUpdatePayload,
} from 'entities/LegalEntity/LegalEntity.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ sellerList, legalEntity, legalEntityLoading, createLegalEntity, updateLegalEntity }) => {
  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
  const [payload, setPayload] = useState<ILegalEntityUpdatePayload | null>(null);
  // TODO Remove
  const [customerListIsChanged, setCustomerListIsChanged] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState<ILegalEntityCustomer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<ILegalEntityCustomer | null>(null);
  const [openCustomerIdEditModal, setOpenCustomerIdEditModal] = useState<boolean>(false);
  const [form] = useForm();
  const { editLegalEntityCardMode, openEditLegalEntityCard, setOpenEditLegalEntityCard } = useLegalEntityContext();

  const isEditMode = editLegalEntityCardMode === EMode.Edit;

  const onClose = () => {
    setFormIsChanged(false);
    setOpenWarningCard(false);
    setOpenEditLegalEntityCard(false);
  };

  const handleClose = () => {
    if (legalEntityLoading) {
      return;
    }

    if (formIsChanged) {
      setOpenEditLegalEntityCard(false);
      setOpenWarningCard(true);
      return;
    }

    onClose();
  };

  const onFormValuesChange = (value: IFormValue) => {
    setFormIsChanged(true);
    setPayload((prev) => (prev ? { ...prev, ...value } : null));
  };

  const onCreateSubmit = (value: ILegalEntityCreatePayload) => {
    createLegalEntity({
      ...value,
      // TODO Remove
      customers: customerListIsChanged
        ? customerList
            .filter((item) => !!item.customerId.length)
            .map((item) => {
              return { customerId: item.customerId, sellerId: item.sellerId };
            })
        : [],
      onSuccess: () => {
        showSuccessMessage('Новое юридическое лицо добавлено в аккаунт.');
        onClose();
      },
    });
  };

  const onUpdateSubmit = () => {
    if (payload) {
      updateLegalEntity({
        ...payload,
        // TODO Remove
        customers: customerListIsChanged
          ? customerList
              .filter((item) => !!item.customerId.length)
              .map((item) => {
                return { customerId: item.customerId, sellerId: item.sellerId };
              })
          : legalEntity?.customers,
        onSuccess: () => {
          showSuccessMessage('Реквизиты юридического лица успешно изменены.');
          onClose();
        },
      });
    }
  };

  // TODO Remove
  useEffect(() => {
    const customerList = sellerList.map((seller) => {
      const legalEntityCustomer = legalEntity?.customers.find((item) => item.sellerId === seller.id);

      return {
        sellerId: seller.id,
        sellerName: seller.name,
        customerId: legalEntityCustomer ? legalEntityCustomer.customerId : '',
      };
    });

    setCustomerList(customerList);
  }, [sellerList, legalEntity]);

  useEffect(() => {
    if (openEditLegalEntityCard) {
      if (isEditMode && legalEntity) {
        setPayload({ id: legalEntity.id });
        form.setFieldsValue({
          name: legalEntity.name,
          inn: legalEntity.inn,
          kpp: legalEntity.kpp,
        });
      } else {
        form.resetFields();
      }
    }
  }, [openEditLegalEntityCard, isEditMode, legalEntity]);

  return (
    <>
      <Drawer
        rootClassName="redesign drawer edit-legal-entity-card"
        destroyOnClose
        width={720}
        open={openEditLegalEntityCard}
        onClose={handleClose}
        footer={
          isEditMode ? (
            <Button className="button-lg primary" loading={legalEntityLoading} onClick={form.submit}>
              Сохранить изменения
            </Button>
          ) : (
            <Button className="button-lg primary" loading={legalEntityLoading} onClick={form.submit}>
              Добавить юр. лицо
            </Button>
          )
        }
      >
        <div className="drawer__body">
          <div className="drawer__title mb-52">{isEditMode ? 'Редактировать юр. лицо' : 'Новое юридическое лицо'}</div>

          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={isEditMode ? onUpdateSubmit : onCreateSubmit}
            onValuesChange={onFormValuesChange}
          >
            <Form.Item className="mb-32" label="Название*" name="name" rules={[rules.required()]}>
              <Input
                placeholder={EPlaceholder.EnterName}
                allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
              />
            </Form.Item>

            <Form.Item
              className="mb-32"
              label="ИНН*"
              name="inn"
              rules={[
                rules.required(),
                rules.min(LEGAL_INN_LENGTH, EFormFieldMessage.LegalInnLength),
                rules.max(LEGAL_INN_LENGTH, EFormFieldMessage.LegalInnLength),
              ]}
            >
              <Input placeholder={EPlaceholder.EnterINN} allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }} />
            </Form.Item>

            <Form.Item
              className="mb-32"
              label="КПП*"
              name="kpp"
              rules={[
                rules.required(),
                rules.min(LEGAL_KPP_LENGTH, EFormFieldMessage.LegalKppLength),
                rules.max(LEGAL_KPP_LENGTH, EFormFieldMessage.LegalKppLength),
              ]}
            >
              <Input placeholder={EPlaceholder.EnterKPP} allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }} />
            </Form.Item>
          </Form>

          {/* TODO Remove */}
          <div className="text-h4 mb-32">ID покупателя</div>

          <div className="mb-32">
            {customerList.map((customer) => {
              return (
                <div
                  key={customer.sellerId}
                  style={{
                    paddingBottom: 16,
                    marginBottom: 16,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #DCDFE1',
                  }}
                >
                  <Label
                    icon={<ContractIcon className="icon-contract-bright-green" />}
                    name={customer.sellerName}
                    color={EColor.BrightGreen}
                  />

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div className="text-controls-accent">{customer.customerId}</div>

                    <Dropdown
                      overlayClassName="redesign"
                      menu={{
                        items: [
                          {
                            key: 'edit',
                            label: 'Редактировать',
                            onClick: () => {
                              setSelectedCustomer(customer);
                              setOpenCustomerIdEditModal(true);
                            },
                          },
                          { type: 'divider' },
                          {
                            key: 'delete',
                            label: 'Удалить ID покупателя',
                            disabled: !customer.customerId.length,
                            onClick: () => {
                              setCustomerListIsChanged(true);
                              setCustomerList((prev) =>
                                prev.map((item) => {
                                  if (item.sellerId === customer.sellerId) {
                                    return { ...item, customerId: '' };
                                  }

                                  return item;
                                }),
                              );
                            },
                            className: 'red',
                          },
                        ],
                      }}
                      placement="bottomRight"
                    >
                      <Button
                        style={{ marginLeft: 16 }}
                        className="button-icon"
                        icon={<OptionsIcon className="icon-options-dark-grey" style={{ transform: 'rotate(90deg)' }} />}
                      />
                    </Dropdown>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Drawer>

      {/* TODO Remove */}
      <Modal
        rootClassName="redesign modal"
        width={400}
        centered
        open={openCustomerIdEditModal}
        destroyOnClose
        onCancel={() => {
          setSelectedCustomer(null);
          setOpenCustomerIdEditModal(false);
        }}
        footer={[
          <Button
            key="cancel"
            className="button-sm secondary"
            onClick={() => {
              setSelectedCustomer(null);
              setOpenCustomerIdEditModal(false);
            }}
          >
            Отмена
          </Button>,
          <Button
            key="save"
            className="button-sm primary"
            onClick={() => {
              if (selectedCustomer) {
                setCustomerListIsChanged(true);
                setCustomerList((prev) =>
                  prev.map((item) => {
                    return item.sellerId === selectedCustomer.sellerId ? selectedCustomer : item;
                  }),
                );
                setSelectedCustomer(null);
                setOpenCustomerIdEditModal(false);
              }
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <div className="mb-20" />

        <div className="input-with-label mb-20">
          <Input
            allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
            value={selectedCustomer?.customerId}
            onChange={(e) => {
              const value = e.target.value;

              if (selectedCustomer) {
                setSelectedCustomer({ ...selectedCustomer, customerId: value });
              }
            }}
          />

          <div className="text-tag mb-8 input-label">ID</div>
        </div>
      </Modal>

      <WarningCard
        open={openWarningCard}
        content="Вы уверены, что не хотите сохранить изменения?"
        subtitle="Введенные данные будут потеряны."
        confirmBtnTitle="Не сохранять"
        onConfirm={onClose}
        onClose={() => {
          setOpenWarningCard(false);
          setOpenEditLegalEntityCard(true);
        }}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  sellerList: state.sellerListState.data,
  legalEntity: state.legalEntityState.data,
  legalEntityLoading: state.legalEntityState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  createLegalEntity: dispatch.legalEntityState.createLegalEntity,
  updateLegalEntity: dispatch.legalEntityState.updateLegalEntity,
});

export const EditLegalEntityCard = connect(mapState, mapDispatch)(Component);
