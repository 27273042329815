export enum EPlaceholder {
  SearchGoodsByNameOrVendorCode = 'Искать по названию или артикулу товара',
  AddVendorCode = 'Добавить артикул',
  SelectAddress = 'Выберите адрес',
  EnterOldPassword = 'Введите старый пароль',
  EnterNewPassword = 'Введите новый пароль',
  RepeatNewPassword = 'Введите новый пароль ещё раз',
  EnterWorkspaceName = 'Введите название карты',
  SelectEmployee = 'Выберите сотрудника',
  SearchByTemplates = 'Поиск по шаблонам',
  Select = 'Выбрать',
  SelectCompany = 'Выберите компанию',
  SelectUser = 'Выберите пользователя',
  SearchByNameOrArticle = 'Искать по названию или артикулу',
  SelectReasonForWriting = 'Выберите причину списания',
  ProvideAdditionalDetailsAboutReasonForWriteOff = 'Укажите дополнительные сведения о причине списания',
  // Redesign
  EnterEmailAddress = 'Введите адрес электронной почты',
  EnterLastName = 'Введите фамилию',
  EnterFirstName = 'Введите имя',
  EnterPatronymic = 'Введите отчество',
  EnterFirstNameLastNameEmail = 'Введите имя, фамилию или e-mail',
  EnterPosition = 'Введите должность',
  EnterContactPhoneNumber = 'Введите контактный телефон',
  EnterName = 'Введите название',
  EnterINN = 'Введите ИНН',
  EnterKPP = 'Введите КПП',
  EnterSubdivisionCode = 'Введите код подразделения',
  IndicateYourPosition = 'Укажите вашу должность',
  SelectSubdivision = 'Выберите подразделение',
  SelectWorkspace = 'Выберите карту оснащения',
  SelectFromEmployeeList = 'Выберите из списка сотрудников',
  SelectFromCompanyLegalEntityList = 'Выберите из списка юр. лиц компании',
  SelectLegalEntity = 'Выберите юр.лицо',
  SelectSubdivisionHead = 'Выберите руководителя подразделения',
  SelectManager = 'Выберите менеджера по закупкам',
  StartTypingAddress = 'Начните вводить ваш адрес',
  SpecifyAddress = 'Укажите адрес',
  SpecifyCity = 'Укажите город',
  SpecifyPostalCode = 'Укажите индекс',
  TransferTo = 'Перенести в...',
  FindAndAddEmployee = 'Найти и добавить сотрудника',
}
