import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';
import { Button, Input, Table } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { debounce } from 'common/helpers/common.helper';
import { useUserContext } from 'common/hooks/useUserContext';
import { DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE, LIST_LIMIT_0, LIST_LIMIT_20 } from 'common/config';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { RootDispatch, RootState } from 'app/store';
import { ISubdivision } from 'entities/Subdivision/Subdivision.models';
import { IUserListParams } from 'entities/User/User.models';
import { renderUserListSettingsRecords } from 'entities/Settings/Settings.helper';
import { SubdivisionUserListSettingsAddUserBtn } from 'entities/Settings/components/SubdivisionSettings/UserList/SubdivisionUserListSettingsAddUserBtn';
import { UserCard } from 'entities/User/components/UserCard';
import { UserEditCard } from 'entities/User/components/UserEditCard';

interface IComponentProps {
  header: ReactNode;
  subdivision: ISubdivision | null;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    subdivision,
    // state
    userListLoading,
    userLoading,
    subdivisionUserList,
    subdivisionUserListCount,
    subdivisionLoading,
    // dispatch
    getUserList,
    getUserById,
    getSubdivisionUserList,
    getSubdivisionUserListPart,
  } = props;

  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [openAddUserModal, setOpenAddUserModal] = useState<boolean>(false);
  const [params, setParams] = useState<IUserListParams>({
    search: '',
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
  });
  const { setOpenUserCard } = useUserContext();

  const debouncedSearchChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    setParams({ ...params, search: e.target.value });
  });

  const onAddUserClick = async () => {
    await getUserList({ excludeSubdivisionId: subdivision?.id, limit: LIST_LIMIT_0 });
    setOpenAddUserModal(true);
  };

  useEffect(() => {
    if (subdivision?.id) {
      getSubdivisionUserList({ ...params, subdivisionId: subdivision.id });
    }
  }, [subdivision?.id, params]);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!subdivisionLoading && subdivisionUserList.length < subdivisionUserListCount}
        scrollToTopTrigger={[params]}
        onLoad={() => {
          setPage(page + 1);
          getSubdivisionUserListPart({ ...params, offset: LIST_LIMIT_20 * page });
        }}
      >
        {header}

        <div className="account-user-list-settings__filters">
          <Input
            placeholder={EPlaceholder.EnterFirstNameLastNameEmail}
            prefix={<SearchIcon className="icon-search-dark-grey" />}
            allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
            // TODO value
            onChange={debouncedSearchChange}
          />

          <SubdivisionUserListSettingsAddUserBtn
            subdivision={subdivision}
            open={openAddUserModal}
            onOpenChange={setOpenAddUserModal}
          />
        </div>

        <Table
          className={`table-hover ${
            !!subdivisionUserList.length && subdivisionUserList.length === subdivisionUserListCount && 'table-end-of-list'
          } ${!subdivisionUserList.length && 'table-empty'} account-user-list-settings__table`}
          showHeader={false}
          pagination={false}
          dataSource={subdivisionUserList.map((user) => ({ ...user, key: user.id }))}
          columns={renderUserListSettingsRecords()}
          loading={{ spinning: subdivisionLoading || userListLoading || userLoading, indicator: <SpinIndicator /> }}
          locale={{
            emptyText: (
              <div className="account-user-list-settings__table-empty">
                <div className="text-body color-dark-grey">В подразделении ещё нет пользователей.</div>

                <Button className="button-sm secondary" onClick={onAddUserClick}>
                  Добавить пользователя
                </Button>
              </div>
            ),
          }}
          onRow={({ id }) => ({
            onClick: () => {
              getUserById({
                id,
                onSuccess: () => setOpenUserCard(true),
              });
            },
          })}
        />
      </InfiniteScrollContainer>

      <UserCard fromSubdivision />

      <UserEditCard />
    </>
  );
};

const mapState = (state: RootState) => ({
  userListLoading: state.userListState.loading,
  userLoading: state.userState.loading,
  subdivisionUserList: state.subdivisionState.userList,
  subdivisionUserListCount: state.subdivisionState.userListCount,
  subdivisionLoading: state.subdivisionState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserList: dispatch.userListState.getUserList,
  getUserById: dispatch.userState.getUserById,
  getSubdivisionUserList: dispatch.subdivisionState.getSubdivisionUserList,
  getSubdivisionUserListPart: dispatch.subdivisionState.getSubdivisionUserListPart,
});

export const SubdivisionUserListSettings = connect(mapState, mapDispatch)(Component);
