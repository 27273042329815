import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  ILegalEntityCreatePayload,
  ILegalEntityDeletePayload,
  ILegalEntityListParams,
  ILegalEntityParams,
  ILegalEntityUpdatePayload,
} from 'entities/LegalEntity/LegalEntity.models';
import { legalEntityTransport } from 'entities/LegalEntity/LegalEntity.transport';

export const legalEntityListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getLegalEntityList(params: ILegalEntityListParams) {
    dispatch.legalEntityListState.setLoading(true);

    try {
      const response = await legalEntityTransport.getLegalEntityList(params);
      dispatch.legalEntityListState.setList(response.data);
      dispatch.legalEntityListState.setCount(response.count);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.legalEntityListState.setLoading(false);
    }
  },
});

export const legalEntityStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getLegalEntityById(params: ILegalEntityParams) {
    dispatch.legalEntityState.setLoading(true);

    try {
      const response = await legalEntityTransport.getLegalEntityById(params.id);
      dispatch.legalEntityState.setLegalEntity(response);
      dispatch.legalEntityState.onSuccess(params);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.legalEntityState.setLoading(false);
    }
  },
  async createLegalEntity(payload: ILegalEntityCreatePayload) {
    dispatch.legalEntityState.setLoading(true);

    try {
      const response = await legalEntityTransport.createLegalEntity(payload);
      dispatch.legalEntityListState.addLegalEntity(response);
      dispatch.legalEntityState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error, dispatch.legalEntityState.setError);
    } finally {
      dispatch.legalEntityState.setLoading(false);
    }
  },
  async updateLegalEntity(payload: ILegalEntityUpdatePayload) {
    dispatch.legalEntityState.setLoading(true);

    try {
      const response = await legalEntityTransport.updateLegalEntity(payload);
      dispatch.legalEntityListState.updateLegalEntity(response);
      dispatch.legalEntityState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error, dispatch.legalEntityState.setError);
    } finally {
      dispatch.legalEntityState.setLoading(false);
    }
  },
  async deleteLegalEntity(payload: ILegalEntityDeletePayload) {
    dispatch.legalEntityState.setLoading(true);

    try {
      const response = await legalEntityTransport.deleteLegalEntity(payload);
      dispatch.legalEntityListState.deleteLegalEntity(response.id);
      dispatch.legalEntityState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.legalEntityState.setLoading(false);
    }
  },
});
