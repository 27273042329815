import React, { FC, ReactNode } from 'react';
import { EColor } from 'common/const/color.enum';

interface IComponentProps {
  icon: ReactNode;
  name: string;
  color: EColor;
  ellipsis?: boolean;
}

export const Label: FC<IComponentProps> = ({ icon, name, color, ellipsis }) => {
  return (
    <div className={`label ${color} ${ellipsis ? 'ellipsis' : ''}`} title={name}>
      <div className="label__icon">{icon}</div>

      <div className={`text-h4-item-name label__name ${ellipsis ? 'ellipsis' : ''}`}>{name}</div>
    </div>
  );
};
