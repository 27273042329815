import axios from 'axios';
import {
  ILegalEntity,
  ILegalCollectionDto,
  ILegalEntityListParams,
  ILegalEntityCreatePayload,
  ILegalEntityDeletePayload,
  ILegalEntityUpdatePayload,
} from 'entities/LegalEntity/LegalEntity.models';

const basePath = 'legal';

export const legalEntityTransport = {
  getLegalEntityList: (params: ILegalEntityListParams): Promise<ILegalCollectionDto> => axios.get(`${basePath}`, { params }),
  getLegalEntityById: (id: number): Promise<ILegalEntity> => axios.get(`${basePath}/${id}`),
  createLegalEntity: (payload: ILegalEntityCreatePayload): Promise<ILegalEntity> => axios.post(`${basePath}`, payload),
  updateLegalEntity: (payload: ILegalEntityUpdatePayload): Promise<ILegalEntity> => axios.patch(`${basePath}`, payload),
  deleteLegalEntity: (payload: ILegalEntityDeletePayload): Promise<ILegalEntity> =>
    axios.delete(`${basePath}`, { data: payload }),
};
