import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Input, Table } from 'antd';
import { SuccessCard } from 'common/components/SuccessCard';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { debounce } from 'common/helpers/common.helper';
import { useUserContext } from 'common/hooks/useUserContext';
import { DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE, LIST_LIMIT_20 } from 'common/config';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { RootDispatch, RootState } from 'app/store';
import { IAccount } from 'entities/Account/Account.models';
import { IInvitationCreatePayload } from 'entities/Invitation/Invitation.models';
import { AccountUserListSettingsAddUserButton } from 'entities/Settings/components/AccountSettings/UserList/AccountUserListSettingsAddUserButton';
import { AccountUserListSettingsFilterBtn } from 'entities/Settings/components/AccountSettings/UserList/AccountUserListSettingsFilterBtn';
import { AccountUserListSettingsFilterChips } from 'entities/Settings/components/AccountSettings/UserList/AccountUserListSettingsFilterChips';
import { renderUserListSettingsRecords } from 'entities/Settings/Settings.helper';
import { UserCard } from 'entities/User/components/UserCard';
import { UserEditCard } from 'entities/User/components/UserEditCard';
import { IUserListParams } from 'entities/User/User.models';

interface IComponentProps {
  header: ReactNode;
  account: IAccount | null;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    account,
    // state
    userList,
    userListCount,
    userListLoading,
    invitationError,
    invitationLoading,
    userLoading,
    // dispatch
    getUserList,
    getUserListPart,
    setInvitationError,
    inviteUser,
    getUserById,
  } = props;

  const [email, setEmail] = useState<string>('');
  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [openSuccessCard, setOpenSuccessCard] = useState<boolean>(false);
  const [params, setParams] = useState<IUserListParams>({
    accountId: account?.id,
    search: '',
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
  });
  const { setOpenUserCard } = useUserContext();

  const debouncedSearchChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    setParams({ ...params, search: e.target.value });
  });

  const onSendInvite = (payload: IInvitationCreatePayload) => {
    setInvitationError(null);
    inviteUser({
      ...payload,
      onSuccess: () => {
        payload.onSuccess?.();
        setEmail(payload.email);
        setOpenSuccessCard(true);
        getUserList(params);
      },
    });
  };

  const onResendInvite = (payload: IInvitationCreatePayload) => {
    inviteUser({
      ...payload,
      onSuccess: () => {
        payload.onSuccess?.();
        setEmail(payload.email);
        setOpenSuccessCard(true);
      },
    });
  };

  useEffect(() => {
    getUserList(params);
  }, [params]);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!userListLoading && userList.length < userListCount}
        scrollToTopTrigger={[params]}
        onLoad={() => {
          setPage(page + 1);
          getUserListPart({ ...params, offset: LIST_LIMIT_20 * page });
        }}
      >
        {header}

        <div className="account-user-list-settings__filters">
          <Input
            placeholder={EPlaceholder.EnterFirstNameLastNameEmail}
            prefix={<SearchIcon className="icon-search-dark-grey" />}
            allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
            // TODO value
            onChange={debouncedSearchChange}
          />

          <AccountUserListSettingsAddUserButton error={invitationError} loading={invitationLoading} onSendInvite={onSendInvite} />

          <AccountUserListSettingsFilterBtn params={params} onParamsChange={setParams} />
        </div>

        <AccountUserListSettingsFilterChips params={params} onParamsChange={setParams} />

        <Table
          className={`table-hover ${!!userList.length && userList.length === userListCount && 'table-end-of-list'} ${
            !userList.length && 'table-empty'
          } account-user-list-settings__table`}
          showHeader={false}
          pagination={false}
          dataSource={userList.map((user) => ({ ...user, key: user.id }))}
          columns={renderUserListSettingsRecords(onResendInvite)}
          locale={{ emptyText: <div className="text-body color-dark-grey">Список пользователей пуст</div> }}
          loading={{ spinning: userListLoading || invitationLoading || userLoading, indicator: <SpinIndicator /> }}
          onRow={({ id }) => ({
            onClick: () => {
              getUserById({
                id,
                onSuccess: () => setOpenUserCard(true),
              });
            },
          })}
        />
      </InfiniteScrollContainer>

      <UserCard />

      <UserEditCard />

      <SuccessCard
        open={openSuccessCard}
        subtitle="Готово!"
        content={`Приглашение успешно отправлено на почту ${email}`}
        btnTitle="Назад к настройкам"
        onClose={() => setOpenSuccessCard(false)}
        onConfirm={() => setOpenSuccessCard(false)}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  userList: state.userListState.data,
  userListCount: state.userListState.count,
  userListLoading: state.userListState.loading,
  invitationError: state.invitationState.error,
  invitationLoading: state.invitationState.loading,
  userLoading: state.userState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserList: dispatch.userListState.getUserList,
  getUserListPart: dispatch.userListState.getUserListPart,
  setInvitationError: dispatch.invitationState.setError,
  inviteUser: dispatch.invitationState.inviteUser,
  getUserById: dispatch.userState.getUserById,
});

export const AccountUserListSettings = connect(mapState, mapDispatch)(Component);
