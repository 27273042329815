import React, { FC, ReactNode, useMemo, useState } from 'react';
import { Input, Select } from 'antd';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { getId, mapToMenuWithDivider } from 'common/helpers/common.helper';
import { SELECT_LIST_HEIGHT_320 } from 'common/config';
import { IOption } from 'common/models';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';

interface IAntdFormControl {
  onChange?: (value?: any) => void;
  value?: any;
}

interface IComponentProps {
  placeholder: EPlaceholder;
  options: IOption[];
  allowClear?: boolean;
  optionRender?: (option: IOption) => ReactNode;
}

export const SelectWithSearch: FC<IComponentProps & IAntdFormControl> = (props) => {
  const { value, onChange, placeholder, options, allowClear, optionRender } = props;

  const [search, setSearch] = useState<string>('');

  const filteredOptions = useMemo(() => {
    return options.filter((option) => (option.label as string)?.toLowerCase().includes(search.toLowerCase()));
  }, [options, search]);
  const optionsForView = mapToMenuWithDivider(filteredOptions);

  return (
    <Select
      allowClear={allowClear ? { clearIcon: <CloseIcon /> } : false}
      rootClassName="redesign select-with-search"
      placeholder={placeholder}
      listHeight={SELECT_LIST_HEIGHT_320}
      virtual={false}
      suffixIcon={<ArrowDownShortIcon />}
      notFoundContent="В списке нет вариантов."
      value={value}
      onChange={onChange}
      optionLabelProp="label"
      onDropdownVisibleChange={(open) => {
        if (!open) {
          setSearch('');
        }
      }}
      dropdownRender={(menu) => {
        return (
          <>
            <Input
              className="mb-20"
              allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            {menu}
          </>
        );
      }}
    >
      {optionsForView.map((option) => {
        if (option.type === 'divider') {
          return <Select.Option key={getId()} type="divider" children={null} />;
        }

        return (
          <Select.Option key={option.value} value={option.value} label={option.label}>
            {optionRender ? optionRender(option) : option.label}
          </Select.Option>
        );
      })}
    </Select>
  );
};
