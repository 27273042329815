import React from 'react';
import { Button, Form, Input } from 'antd';
import { connect } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom';
import { EFormFieldMessage } from 'common/const/form.enum';
import { ERoute } from 'common/const/route.enum';
import { rules } from 'common/helpers/form.helper';
import { ReactComponent as ProfileIcon } from 'app/assets/images/profile.svg';
import { RootDispatch, RootState } from 'app/store';
import { AuthCard } from 'entities/Auth/components/AuthCard';
import { IRestorePasswordEmailPayload } from 'entities/Auth/Auth.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const SendRestorePasswordEmailComponent: React.FC<AllType> = ({ authState, sendRestorePasswordEmail, setAuthError }) => {
  const { loading, error } = authState;
  const [form] = useForm();
  const navigate = useNavigate();

  const onCancelClick = () => {
    setAuthError(null);

    navigate(ERoute.Login, { replace: true });
  };

  const onSubmit = (values: IRestorePasswordEmailPayload) => {
    setAuthError(null);

    sendRestorePasswordEmail({
      email: values.email,
      onSuccess: () => {
        navigate(ERoute.RestorePasswordConfirmation, { replace: true, state: { email: values.email } });
      },
    });
  };

  return (
    <div className="auth">
      <AuthCard
        form={form}
        loading={loading}
        body={
          <>
            <Form.Item
              label="Восстановление пароля"
              name="email"
              rules={[rules.required(), rules.type('email', EFormFieldMessage.InvalidEmailFormat)]}
            >
              <Input placeholder="Введите адрес электронной почты" prefix={<ProfileIcon />} />
            </Form.Item>

            {!!error && (
              <div className="auth-card__error-block">
                <span className="auth-card__error-block-item">{error}</span>
              </div>
            )}
          </>
        }
        footer={
          <>
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отмена
            </Button>

            <Button className="btn btn-primary" htmlType="submit">
              Восстановить
            </Button>
          </>
        }
        onSubmit={onSubmit}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  authState: state.authState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  sendRestorePasswordEmail: dispatch.authState.sendRestorePasswordEmail,
  setAuthError: dispatch.authState.setError,
});

export const SendRestorePasswordEmail = connect(mapState, mapDispatch)(SendRestorePasswordEmailComponent);
