import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, Input, Modal, Spin, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { debounce } from 'common/helpers/common.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { LIST_LIMIT_0 } from 'common/config';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as InfoYellowIcon } from 'app/assets/images/redesign/info-yellow.svg';
import { RootDispatch, RootState } from 'app/store';
import { getSubdivisionSettingsBreadcrumbItems, getSubdivisionTreeKeysBySubdivisionId } from 'entities/Settings/Settings.helper';
import { ISubdivision } from 'entities/Subdivision/Subdivision.models';
import { IUser, IUserListParams } from 'entities/User/User.models';
import { getUserName } from 'entities/User/User.helper';

interface IComponentProps {
  subdivision: ISubdivision | null;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    subdivision,
    open,
    onOpenChange,
    // state
    userList,
    userListLoading,
    subdivisionList,
    userLoading,
    // dispatch
    getUserList,
    updateUserByAdmin,
    addSubdivisionUser,
  } = props;

  const [openNotificationModal, setOpenNotificationModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [params, setParams] = useState<IUserListParams>({ limit: LIST_LIMIT_0 });

  const subdivisionTreeKeys = getSubdivisionTreeKeysBySubdivisionId(subdivisionList, selectedUser?.subdivision?.id.toString());
  const subdivisionBreadcrumbItems = getSubdivisionSettingsBreadcrumbItems(subdivisionList, subdivisionTreeKeys);

  const onOpenModalClick = async () => {
    await getUserList(params);
    onOpenChange(true);
  };

  const onSearchChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setParams((prev) => ({ ...prev, search: value }));
    getUserList({ ...params, search: value });
  });

  const onClose = () => {
    setSelectedUser(null);
    setParams((prev) => ({ ...prev, search: '' }));
    onOpenChange(false);
    setOpenNotificationModal(false);
  };

  const onAddUserClick = () => {
    if (subdivision?.id && selectedUser) {
      updateUserByAdmin({
        id: selectedUser.id,
        subdivisionId: subdivision.id,
        onSuccess: () => {
          showSuccessMessage(`Пользователь «${selectedUser.email}» добавлен в подразделение.`);
          onClose();
          addSubdivisionUser({
            ...selectedUser,
            subdivisionId: subdivision.id,
            subdivision: subdivision,
          });
        },
      });
    }
  };

  const handleAddUserClick = () => {
    if (selectedUser?.subdivision) {
      onOpenChange(false);
      setOpenNotificationModal(true);
      return;
    }

    onAddUserClick();
  };

  const onNotificationClose = () => {
    setOpenNotificationModal(false);
    onOpenChange(true);
    setParams((prev) => ({ ...prev, search: '' }));
    getUserList({ ...params, search: '' });
  };

  useEffect(() => {
    if (subdivision?.id) {
      setParams((prev) => ({ ...prev, excludeSubdivisionId: subdivision.id }));
    }
  }, [subdivision?.id]);

  return (
    <>
      <Tooltip title="Добавить сотрудника" placement="bottom">
        <Button className="button-circle primary" icon={<PlusIcon className="icon-plus-white" />} onClick={onOpenModalClick} />
      </Tooltip>

      <Modal
        rootClassName="redesign modal"
        width={400}
        centered
        open={open}
        onCancel={onClose}
        destroyOnClose
        closeIcon={false}
        footer={[
          <Button key="cancel" className="button-sm secondary" onClick={onClose} loading={userLoading}>
            Отмена
          </Button>,
          <Button
            key="add"
            className="button-sm primary"
            loading={userLoading}
            disabled={!selectedUser}
            onClick={handleAddUserClick}
          >
            Добавить
          </Button>,
        ]}
      >
        <Input
          className="mb-20"
          placeholder={EPlaceholder.FindAndAddEmployee}
          allowClear={{ clearIcon: <CloseIcon className="icon-close-input" /> }}
          onChange={onSearchChange}
        />

        <Spin spinning={userListLoading} indicator={<SpinIndicator />}>
          <div className="scrollbar subdivision-user-list-settings-add-user-btn__scroll-container">
            {userList.length ? (
              userList.map((user) => {
                return (
                  <Button
                    key={user.id}
                    className={`subdivision-user-list-settings-add-user-btn__list-item ${
                      user.id === selectedUser?.id ? 'active' : ''
                    }`}
                    onClick={() => setSelectedUser(user)}
                  >
                    <div className="user-initials dark-grey">{getUserName.initials(user.lastName, user.firstName)}</div>

                    <div>
                      <div className="text-controls-accent color-black mb-4">
                        {getUserName.fullName(user.lastName, user.firstName, user.patronymic)}
                      </div>

                      <span className="text-tag color-dark-grey">{user.email}</span>
                    </div>
                  </Button>
                );
              })
            ) : (
              <div className="text-body color-dark-grey subdivision-user-list-settings-add-user-btn__list-empty">
                Список пользователей пуст
              </div>
            )}
          </div>
        </Spin>
      </Modal>

      <Modal
        rootClassName="redesign modal"
        width={440}
        centered
        open={openNotificationModal}
        onCancel={onNotificationClose}
        destroyOnClose
        footer={[
          <Button key="cancel" className="button-sm secondary" onClick={onNotificationClose} loading={userLoading}>
            Отмена
          </Button>,
          <Button key="accept" className="button-sm primary" loading={userLoading} onClick={onAddUserClick}>
            Подтвердить
          </Button>,
        ]}
      >
        <div className="mb-24 subdivision-user-list-settings-add-user-btn__info-icon">
          <InfoYellowIcon className="icon-info-yellow" />
        </div>

        <div className="text-h4 mb-12">Переназначение подразделения</div>

        <div className="text-controls color-dark-grey mb-12">{`Пользователь ${selectedUser?.email} уже привязан к подразделению «${subdivisionBreadcrumbItems
          .map((item) => item.title)
          .join('/')}».`}</div>

        <div className="text-controls color-dark-grey mb-20">Вы уверены, что хотите изменить назначение?</div>
      </Modal>
    </>
  );
};

const mapState = (state: RootState) => ({
  userList: state.userListState.data,
  userListLoading: state.userListState.loading,
  subdivisionList: state.subdivisionListState.data,
  userLoading: state.userState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserList: dispatch.userListState.getUserList,
  updateUserByAdmin: dispatch.userState.updateUserByAdmin,
  addSubdivisionUser: dispatch.subdivisionState.addUser,
});

export const SubdivisionUserListSettingsAddUserBtn = connect(mapState, mapDispatch)(Component);
