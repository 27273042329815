import React, { FC, ReactNode, useMemo } from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { ESupplyListMenuTab } from 'common/const/supply.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { RootState } from 'app/store';
import { CreatedSupplyListForSeller } from 'entities/Supply/components/Seller/CreatedSupplyListForSeller';
import { ProcessingSupplyListForSeller } from 'entities/Supply/components/Seller/ProcessingSupplyListForSeller';
import { PendingSupplyListForSeller } from 'entities/Supply/components/Seller/PendingSupplyListForSeller';
import { getNeedListFilterOptions } from 'entities/Need/Need.helper';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const Component: FC<AllType> = ({ header, navigation, buyerList, subdivisionList, addressList, userList }) => {
  const { getSearchParam, setSearchParam } = useSearchParamsHook();

  const buyerOptions = getNeedListFilterOptions.buyer(buyerList);
  const subdivisionOptions = getNeedListFilterOptions.subdivision(subdivisionList);
  const addressOptions = getNeedListFilterOptions.address(addressList);
  const userOptions = getNeedListFilterOptions.user(userList);
  const tab = getSearchParam(ESearchParams.Tab);
  const isProcessingSupplyListPage = tab === ESupplyListMenuTab.Processing;
  const isPendingSupplyListPage = tab === ESupplyListMenuTab.Pending;

  const menu = useMemo(() => {
    return (
      <Menu
        items={[
          { key: ESupplyListMenuTab.Created, label: 'Новые заказы' },
          { key: ESupplyListMenuTab.Processing, label: 'Переданы в учётную систему' },
          { key: ESupplyListMenuTab.Pending, label: 'На рассмотрении у заявителя' },
        ]}
        selectedKeys={[tab === null ? ESupplyListMenuTab.Created : tab]}
        onClick={({ key }) => setSearchParam(ESearchParams.Tab, key)}
        mode="horizontal"
      />
    );
  }, [tab]);

  return isProcessingSupplyListPage ? (
    <ProcessingSupplyListForSeller
      header={header}
      navigation={navigation}
      menu={menu}
      buyerOptions={buyerOptions}
      subdivisionOptions={subdivisionOptions}
      addressOptions={addressOptions}
      userOptions={userOptions}
    />
  ) : isPendingSupplyListPage ? (
    <PendingSupplyListForSeller
      header={header}
      navigation={navigation}
      menu={menu}
      buyerOptions={buyerOptions}
      subdivisionOptions={subdivisionOptions}
      addressOptions={addressOptions}
      userOptions={userOptions}
    />
  ) : (
    <CreatedSupplyListForSeller
      header={header}
      navigation={navigation}
      menu={menu}
      buyerOptions={buyerOptions}
      subdivisionOptions={subdivisionOptions}
      addressOptions={addressOptions}
      userOptions={userOptions}
    />
  );
};

const mapState = (state: RootState) => ({
  buyerList: state.buyerListState.data,
  subdivisionList: state.supplyListState.subdivisionList,
  addressList: state.supplyListState.addressList,
  userList: state.supplyListState.userList,
});

export const IncomingRequestsForSeller = connect(mapState)(Component);
