import '@axmit/garwin-api';
import { IRequestSuccess } from 'common/models';
import { IUser } from 'entities/User/User.models';

export interface IAuthLoginPayload extends Components.Schemas.LoginRequest {}
export interface IAuth extends Components.Schemas.LoginResponse {}
export interface IRestorePasswordEmailPayload extends Components.Schemas.SendRestorePasswordEmailDto, IRequestSuccess {}
export interface IRestorePasswordPayload extends Components.Schemas.RestorePasswordDto {
  passwordConfirm?: string;
}

export interface IToken {
  access: {
    id: number;
    token: string;
    userId: number;
    issuedAt: number;
    expiredAt: number;
    accountId: number;
    accountName: string;
    firstName?: string;
    lastName?: string;
    patronymic?: string;
    roles: string[];
    isSeller: boolean;
    subdivisionName?: string;
    basketId?: number;
  };
  refresh: {
    id: number;
    token: string;
    userId: number;
    issuedAt: number;
    expiredAt: number;
  };
}

export interface IAuthState {
  data: IToken | null;
  loading: boolean;
  error: string | null;
  superAdminAsUser: boolean;
  userCatalogId: number | null;
  xBasketId: number | null;
}

export interface ILoginAsUserPayload {
  user: IUser;
  isSeller: boolean;
}

export const refreshTokenExpiration = 30 * 86400; // = 30 days
