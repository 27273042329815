import React, { FC, useState } from 'react';
import { Checkbox, Dropdown } from 'antd';
import { EUserRole } from 'common/const/user.enum';
import { ReactComponent as UserIcon } from 'app/assets/images/redesign/user.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as ArrowUpShortIcon } from 'app/assets/images/redesign/arrow-up-short.svg';
import { getUserRole } from 'entities/User/User.helper';

interface IComponentProps {
  roles: string[];
  isAccountOwner: boolean;
  isSeller?: boolean;
  onChange: (value: string[]) => void;
}

export const UserCardRoleSelector: FC<IComponentProps> = ({ roles, isAccountOwner, isSeller, onChange }) => {
  const [open, setOpen] = useState<boolean>(false);

  const { isAccountAdmin, isManager, isSellerManager, isHeadOfDepartment } = getUserRole(roles);
  const checkAll = isAccountAdmin && (isSeller ? isSellerManager : isManager);

  const onAllChange = (checked: boolean) => {
    const newRoles = [EUserRole.User];

    if (checked) {
      newRoles.push(EUserRole.AccountAdmin, isSeller ? EUserRole.SellerManager : EUserRole.Manager);
    }

    if (!checked && isAccountOwner) {
      newRoles.push(EUserRole.AccountAdmin);
    }

    if (isHeadOfDepartment) {
      newRoles.push(EUserRole.HeadOfDepartment);
    }

    onChange(newRoles);
  };

  const onRolesChange = (checked: boolean, value: string) => {
    const newRoles = checked ? [...roles, value] : roles.filter((role) => role !== value);

    onChange(newRoles);
  };

  return (
    <Dropdown
      overlayClassName="redesign"
      trigger={['click']}
      placement="bottomLeft"
      onOpenChange={(value) => setOpen(value)}
      dropdownRender={() => {
        return (
          <div className="custom-dropdown" style={{ width: 364 }}>
            <Checkbox className="checkbox-with-description" checked={checkAll} onChange={(e) => onAllChange(e.target.checked)}>
              <div className="checkbox-with-description__label">Все</div>
            </Checkbox>

            <div className="divider mb-8" style={{ marginTop: 8 }} />

            {isSeller ? (
              <Checkbox
                className="checkbox-with-description"
                disabled={!isSeller}
                checked={isSellerManager}
                onChange={(e) => onRolesChange(e.target.checked, EUserRole.SellerManager)}
              >
                <div className="checkbox-with-description__label">Аккаунт-менеджер</div>

                {/* TODO */}
                <div className="checkbox-with-description__description">Описание роли и доступного функционала</div>
              </Checkbox>
            ) : (
              <Checkbox
                className="checkbox-with-description"
                disabled={isSeller}
                checked={isManager}
                onChange={(e) => onRolesChange(e.target.checked, EUserRole.Manager)}
              >
                <div className="checkbox-with-description__label">Закупщик</div>

                <div className="checkbox-with-description__description">Доступ к управлению заказами сотрудников</div>
              </Checkbox>
            )}

            <div className="divider mb-8" style={{ marginTop: 8 }} />

            <Checkbox
              className="checkbox-with-description"
              disabled={isAccountOwner}
              checked={isAccountAdmin}
              onChange={(e) => onRolesChange(e.target.checked, EUserRole.AccountAdmin)}
            >
              <div className="checkbox-with-description__label">Администратор аккаунта</div>

              <div className="checkbox-with-description__description">Доступ ко всем функциям системы</div>
            </Checkbox>
          </div>
        );
      }}
    >
      <div className={`user-card-role-selector ${open ? 'opened' : ''}`}>
        <UserIcon className="icon-user-dark-grey" />

        <span className="text-tag-accent color-dark-grey">Назначить роль</span>

        {open ? (
          <ArrowUpShortIcon className="icon-arrow-up-short-dark-grey" />
        ) : (
          <ArrowDownShortIcon className="icon-arrow-down-short-dark-grey" />
        )}
      </div>
    </Dropdown>
  );
};
