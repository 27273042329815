import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { destroyMessage, showLoadingMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { IRootModel } from 'app/store';
import {
  IBrandDiscount,
  IBrandDiscountCreatePayload,
  IBrandDiscountDeletePayload,
  IBrandDiscountState,
  IBrandsDiscountListParams,
  IBrandsDiscountListState,
  IDiscount,
  IDiscountListParams,
  IDiscountListState,
  IDiscountCreatePayload,
  IDiscountState,
  IDiscountUpdatePayload,
} from 'entities/Discount/Discount.models';
import { discountTransport } from 'entities/Discount/Discount.transport';

export const discountListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IDiscountListState,
  reducers: {
    setDiscountList: (state, payload: IDiscount[]) => ({ ...state, data: payload }),
    setDiscountListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    addDiscount: (state, payload: IDiscount) => ({ ...state, data: [...state.data, payload] }),
  },
  effects: (dispatch) => ({
    async getDiscountList(params: IDiscountListParams) {
      dispatch.discountListState.setDiscountListLoading(true);

      try {
        const response = await discountTransport.getDiscountList(params);
        dispatch.discountListState.setDiscountList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.discountListState.setDiscountListLoading(false);
      }
    },
  }),
});

export const discountState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IDiscountState,
  reducers: {
    setDiscount: (state, payload: IDiscount) => ({ ...state, data: payload }),
    setDiscountLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getDiscountById(id: number) {
      dispatch.discountState.setDiscountLoading(true);

      try {
        const response = await discountTransport.getDiscountById(id);
        dispatch.discountState.setDiscount(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.discountState.setDiscountLoading(false);
      }
    },
    async createDiscount(payload: IDiscountCreatePayload) {
      dispatch.discountState.setDiscountLoading(true);

      try {
        const response = await discountTransport.createDiscount(payload);
        dispatch.discountListState.addDiscount(response);
        dispatch.legalEntityState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.discountState.setDiscountLoading(false);
      }
    },
    async updateDiscount(payload: IDiscountUpdatePayload) {
      dispatch.discountState.setDiscountLoading(true);
      showLoadingMessage(0);

      try {
        const response = await discountTransport.updateDiscount(payload);
        dispatch.discountState.setDiscount(response);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error);
      } finally {
        dispatch.discountState.setDiscountLoading(false);
      }
    },
  }),
});

export const brandsDiscountListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IBrandsDiscountListState,
  reducers: {
    setBrandsDiscountList: (state, payload: IBrandDiscount[]) => ({ ...state, data: payload }),
    setBrandsDiscountLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    addBrandDiscount: (state, payload: IBrandDiscount) => ({ ...state, data: [...state.data, payload] }),
    deleteBrandDiscount: (state, payload: number) => ({
      ...state,
      data: state.data.filter((discount) => discount.id !== payload),
    }),
  },
  effects: (dispatch) => ({
    async getBrandsDiscountList(params: IBrandsDiscountListParams) {
      dispatch.brandsDiscountListState.setBrandsDiscountLoading(true);

      try {
        const response = await discountTransport.getBrandsDiscountList(params);
        dispatch.brandsDiscountListState.setBrandsDiscountList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.brandsDiscountListState.setBrandsDiscountLoading(false);
      }
    },
  }),
});

export const brandDiscountState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IBrandDiscountState,
  reducers: {
    setBrandDiscount: (state, payload: IBrandDiscount) => ({ ...state, data: payload }),
    setBrandDiscountLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async createBrandDiscount(payload: IBrandDiscountCreatePayload) {
      dispatch.brandDiscountState.setBrandDiscountLoading(true);

      try {
        const response = await discountTransport.createBrandDiscount(payload);
        dispatch.brandsDiscountListState.addBrandDiscount(response);
        dispatch.brandDiscountState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.brandDiscountState.setBrandDiscountLoading(false);
      }
    },
    async deleteBrandDiscount(payload: IBrandDiscountDeletePayload) {
      dispatch.brandDiscountState.setBrandDiscountLoading(true);

      try {
        const response = await discountTransport.deleteBrandDiscount(payload);
        dispatch.brandsDiscountListState.deleteBrandDiscount(response.id);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.brandDiscountState.setBrandDiscountLoading(false);
      }
    },
  }),
});
