import React, { FC, ReactNode, useMemo } from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { CountBadge } from 'common/components/CountBadge';
import { EInventoryListMenuTab } from 'common/const/inventory.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { DEFAULT_VALUE_0 } from 'common/config';
import { RootState } from 'app/store';
import { TransferIncomingInventoryActionList } from 'entities/Inventory/components/Transfer/TransferIncomingInventoryActionList';
import { TransferSentInventoryActionList } from 'entities/Inventory/components/Transfer/TransferSentInventoryActionList';
import { getInventoryListFilterOptions } from 'entities/Inventory/Inventory.helper';
import { IUserInventoryStatistics } from 'entities/User/User.models';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  statistics: IUserInventoryStatistics;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const { header, navigation, statistics, inventoryActionListSubdivisions, inventoryActionListUsers } = props;

  const { getSearchParam, setSearchParam } = useSearchParamsHook();

  const { incomingTransfersCount, outcomingTransfersCount } = statistics;
  const tab = getSearchParam(ESearchParams.Tab);
  const defaultTab = incomingTransfersCount > DEFAULT_VALUE_0 ? EInventoryListMenuTab.Incoming : EInventoryListMenuTab.Sent;
  const isTransferSentInventoryActionList = tab === EInventoryListMenuTab.Sent;
  const subdivisionOptions = getInventoryListFilterOptions.subdivision(inventoryActionListSubdivisions);
  const userOptions = getInventoryListFilterOptions.user(inventoryActionListUsers);

  const getMenuItems = () => {
    const items = [];

    if (incomingTransfersCount > DEFAULT_VALUE_0) {
      items.push({
        key: EInventoryListMenuTab.Incoming,
        label: (
          <>
            Входящие запросы
            <CountBadge count={incomingTransfersCount} />
          </>
        ),
      });
    }

    if (outcomingTransfersCount > DEFAULT_VALUE_0) {
      items.push({
        key: EInventoryListMenuTab.Sent,
        label: (
          <>
            Отправленные запросы
            <CountBadge count={outcomingTransfersCount} />
          </>
        ),
      });
    }

    return items;
  };

  const menu = useMemo(() => {
    return (
      <Menu
        items={getMenuItems()}
        selectedKeys={[tab === null ? defaultTab : tab]}
        onClick={({ key }) => setSearchParam(ESearchParams.Tab, key)}
        mode="horizontal"
      />
    );
  }, [tab, defaultTab, statistics]);

  return isTransferSentInventoryActionList ? (
    <TransferSentInventoryActionList
      header={header}
      navigation={navigation}
      menu={menu}
      subdivisionOptions={subdivisionOptions}
      userOptions={userOptions}
    />
  ) : (
    <TransferIncomingInventoryActionList
      header={header}
      navigation={navigation}
      menu={menu}
      subdivisionOptions={subdivisionOptions}
      userOptions={userOptions}
    />
  );
};

const mapState = (state: RootState) => ({
  inventoryActionListSubdivisions: state.inventoryActionListState.subdivisionList,
  inventoryActionListUsers: state.inventoryActionListState.userList,
});

export const TransferInventoryActionList = connect(mapState)(Component);
