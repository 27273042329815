import React, { FC, Key, useEffect, useMemo, useState } from 'react';
import { Button, Tooltip, Tree } from 'antd';
import { AntTreeNodeProps } from 'antd/es/tree';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { WarningCard } from 'common/components/WarningCard';
import { ESettingsMenuTab, ESettingsTab } from 'common/const/settings.enum';
import { ERoute } from 'common/const/route.enum';
import { EOrderDirection, EOrderField } from 'common/const/common.enum';
import { showMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { stringToNumber } from 'common/helpers/common.helper';
import { filterTreeDataByKey, getSelectedKeyFromTreeKey } from 'common/helpers/tree.helper';
import { useSubdivisionContext } from 'common/hooks/useSubdivisionContext';
import { LIST_LIMIT_0 } from 'common/config';
import { ReactComponent as SettingsIcon } from 'app/assets/images/redesign/settings.svg';
import { ReactComponent as ArrowRightShortIcon } from 'app/assets/images/redesign/arrow-right-short.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { SubdivisionTransferModal } from 'entities/Settings/components/SubdivisionTransferModal';
import { SettingsSidebarBackBtn } from 'entities/Settings/components/SettingsSidebarBackBtn';
import { getSubdivisionTreeKeysBySubdivisionId, mapSubdivisionListToTreeData } from 'entities/Settings/Settings.helper';
import { SubdivisionCreateCard } from 'entities/Subdivision/components/SubdivisionCreateCard';
import { ISubdivision } from 'entities/Subdivision/Subdivision.models';
import { findSubdivisionChildrenIds } from 'entities/Subdivision/Subdivision.helper';

interface IComponentProps {
  subdivisionList: ISubdivision[];
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    subdivisionList,
    // state
    currentUser,
    subdivisionLoading,
    isSidebarOpen,
    // dispatch
    getUserList,
    getLegalEntityList,
    filterSubdivisionList,
    updateSubdivision,
    deleteSubdivision,
  } = props;

  const [subdivisionTreeKey, setSubdivisionTreeKey] = useState<Key>('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [expandedTreeKeys, setExpandedTreeKeys] = useState<Key[]>([]);
  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const [showSubdivisionTree, setShowSubdivisionTree] = useState<boolean>(false);
  const [openSubdivisionTransferModal, setOpenSubdivisionTransferModal] = useState<boolean>(false);
  const { tab, id, menu } = useParams();
  const navigate = useNavigate();
  const { setParentId, setOpenSubdivisionCreateCard } = useSubdivisionContext();

  const accountId = currentUser?.account?.id;
  const isAccountSettings = tab === ESettingsTab.Account;
  const selectedSubdivisionTreeKeys = useMemo(
    () => getSubdivisionTreeKeysBySubdivisionId(subdivisionList, id),
    [subdivisionList, id],
  );

  const onCreateSubdivisionClick = async (key?: Key) => {
    const parentId = key ? stringToNumber(getSelectedKeyFromTreeKey(key)) : undefined;

    await getUserList({
      accountId,
      orderField: EOrderField.LastName,
      orderDirection: EOrderDirection.ASC,
      limit: LIST_LIMIT_0,
    });
    await getLegalEntityList({ limit: LIST_LIMIT_0 });
    setParentId(parentId);
    setOpenSubdivisionCreateCard(true);
  };

  const onTransferSubdivisionClick = (key: Key) => {
    setSubdivisionTreeKey(key);
    setOpenSubdivisionTransferModal(true);
  };

  const onTransferSubdivisionSaveClick = (parentId: number, onClose: () => void) => {
    const subdivisionKey = getSelectedKeyFromTreeKey(subdivisionTreeKey);

    updateSubdivision({
      id: stringToNumber(subdivisionKey),
      parentId,
      onSuccess: () => {
        showSuccessMessage('Подразделение успешно перенесено.');
        onClose();
      },
    });
  };

  const onDeleteSubdivisionClick = (key: Key) => {
    const subdivisionKey = getSelectedKeyFromTreeKey(key);

    setSubdivisionTreeKey(subdivisionKey);
    setOpenWarningCard(true);
  };

  const onDeleteSubdivisionConfirmClick = () => {
    const subdivisionId = stringToNumber(subdivisionTreeKey as string);

    deleteSubdivision({
      id: subdivisionId,
      onSuccess: () => {
        const subdivision = subdivisionList.find((item) => item.id === subdivisionId) as ISubdivision;
        const subdivisionAndChildrenIds = findSubdivisionChildrenIds(subdivisionId, subdivisionList);

        setOpenWarningCard(false);
        showMessage({
          content: `Подразделение ${subdivision.name} удалено.`,
          icon: <BinIcon className="icon-bin-dark-grey" />,
        });
        filterSubdivisionList(subdivisionAndChildrenIds);

        if (id && subdivisionAndChildrenIds.includes(stringToNumber(id))) {
          if (subdivision.parentId) {
            navigate(`${ERoute.Settings}/${ESettingsTab.Subdivision}/${subdivision.parentId}/${ESettingsMenuTab.General}`);
          } else {
            navigate(`${ERoute.Settings}/${ESettingsTab.Account}/${ESettingsMenuTab.Contracts}`);
          }
        }
      },
    });
  };

  const onExpandTreeKeys = (keys: Key[]) => {
    setExpandedTreeKeys(keys);
    setAutoExpandParent(false);
  };

  const subdivisionTreeData = useMemo(() => {
    return mapSubdivisionListToTreeData(
      subdivisionList,
      onCreateSubdivisionClick,
      onTransferSubdivisionClick,
      onDeleteSubdivisionClick,
    );
  }, [subdivisionList, id]);

  const filteredSubdivisionTreeData = useMemo(() => {
    return filterTreeDataByKey(subdivisionTreeData, subdivisionTreeKey);
  }, [subdivisionTreeData, subdivisionTreeKey]);

  useEffect(() => {
    if (selectedSubdivisionTreeKeys.length) {
      setShowSubdivisionTree(true);
      setExpandedTreeKeys((prev) => [...new Set([...prev, ...selectedSubdivisionTreeKeys])]);
      setAutoExpandParent(true);
    }
  }, [selectedSubdivisionTreeKeys]);

  return (
    <>
      <div className={`settings-sidebar ${isSidebarOpen ? 'collapsed' : ''}`}>
        <InfiniteScrollContainer>
          <div className="mb-40">
            <SettingsSidebarBackBtn />
          </div>

          <div
            style={{ cursor: 'pointer' }}
            className={`settings-sidebar__btn ${isAccountSettings ? 'active' : ''}`}
            onClick={() => navigate(`${ERoute.Settings}/${ESettingsTab.Account}/${ESettingsMenuTab.Contracts}`)}
          >
            <div className="settings-sidebar__btn-container">
              <SettingsIcon className="icon-settings-dark-grey" />

              <span className="text-h4-item-name color-black settings-sidebar__btn-text">Настройки аккаунта</span>
            </div>

            <span className="text-tag color-dark-grey settings-sidebar__btn-text">
              Контракты, пользователи и другие настройки
            </span>
          </div>

          <div className={`settings-sidebar__btn ${!isAccountSettings ? 'active' : ''}`}>
            <div className="settings-sidebar__btn-container">
              <Button
                className="button-icon settings-sidebar__btn-toggle"
                icon={
                  showSubdivisionTree ? (
                    <ArrowDownShortIcon className="icon-arrow-down-short-dark-grey" />
                  ) : (
                    <ArrowRightShortIcon className="icon-arrow-right-short-dark-grey" />
                  )
                }
                onClick={() => setShowSubdivisionTree((prev) => !prev)}
              />

              <div className="settings-sidebar__btn-title-container">
                <span className="text-h4-item-name settings-sidebar__btn-text">Подразделения</span>

                <Tooltip title="Новое подразделение" placement="bottom">
                  <Button
                    className="button-icon settings-sidebar__btn-create"
                    icon={<PlusIcon className="icon-plus-dark-grey" />}
                    onClick={() => onCreateSubdivisionClick()}
                  />
                </Tooltip>
              </div>
            </div>

            <span className="text-tag color-dark-grey settings-sidebar__btn-text">Настройки и создание подразделений</span>
          </div>

          {showSubdivisionTree && (
            <Tree
              className="settings-sidebar__subdivision-tree mb-52"
              treeData={subdivisionTreeData}
              showIcon
              switcherIcon={(props: AntTreeNodeProps) => {
                return props.expanded ? (
                  <ArrowDownShortIcon className="icon-arrow-down-short-dark-grey" />
                ) : (
                  <ArrowRightShortIcon className="icon-arrow-right-short-dark-grey" />
                );
              }}
              selectedKeys={selectedSubdivisionTreeKeys}
              expandedKeys={expandedTreeKeys}
              autoExpandParent={autoExpandParent}
              onExpand={onExpandTreeKeys}
              onSelect={(keys) => {
                if (!keys.length) {
                  return;
                }

                const subdivisionKey = getSelectedKeyFromTreeKey(keys[0]);

                navigate(
                  `${ERoute.Settings}/${ESettingsTab.Subdivision}/${subdivisionKey}/${
                    tab !== ESettingsTab.Subdivision ? ESettingsMenuTab.General : menu
                  }`,
                );
              }}
            />
          )}
        </InfiniteScrollContainer>
      </div>

      <SubdivisionCreateCard />

      <SubdivisionTransferModal
        open={openSubdivisionTransferModal}
        treeData={filteredSubdivisionTreeData}
        loading={subdivisionLoading}
        onClose={() => setOpenSubdivisionTransferModal(false)}
        onSave={onTransferSubdivisionSaveClick}
      />

      <WarningCard
        open={openWarningCard}
        content="Вы уверены, что хотите удалить подразделение?"
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Удалить подразделение"
        loading={subdivisionLoading}
        onConfirm={onDeleteSubdivisionConfirmClick}
        onClose={() => setOpenWarningCard(false)}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  currentUser: state.userState.currentUser,
  subdivisionLoading: state.subdivisionState.loading,
  isSidebarOpen: state.UIState.isSidebarOpen,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserList: dispatch.userListState.getUserList,
  getLegalEntityList: dispatch.legalEntityListState.getLegalEntityList,
  filterSubdivisionList: dispatch.subdivisionListState.filterList,
  updateSubdivision: dispatch.subdivisionState.updateSubdivision,
  deleteSubdivision: dispatch.subdivisionState.deleteSubdivision,
});

export const SettingsSidebar = connect(mapState, mapDispatch)(Component);
