export enum ESettingsTab {
  Account = 'account',
  Subdivision = 'subdivision',
}

export enum ESettingsMenuTab {
  Account = 'account',
  Subdivisions = 'subdivisions',
  Discounts = 'discounts',
  Stocks = 'stocks',
  Integration = 'integration',
  AccountList = 'account-list',
  // Redesign
  Contracts = 'contracts',
  UserList = 'user-list',
  LegalEntityList = 'legal-entity-list',
  General = 'general',
  AddressList = 'address-list',
}

export enum EAccountTariffPlanTitle {
  free = 'Бесплатный',
}
