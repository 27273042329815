import React from 'react';
import { EPropertyCascaderItemType } from 'common/const/property.enum';
import { EPresetType } from 'common/const/preset.enum';
import { EPropertyType } from 'common/const/property.enum';
import { sortProperties } from 'common/helpers/properties.helper';
import { getId, numberToString, stringToNumber } from 'common/helpers/common.helper';
import { dataToTree } from 'common/helpers/tree.helper';
import { rules } from 'common/helpers/form.helper';
import {
  IPropertyListState,
  IPropertyCascaderItem,
  IProperty,
  IPropertyValue,
  IPropertyCascaderState,
  IPropertyCascaderItemSelectPropertyPayload,
  IPropertyCascaderItemProperty,
} from 'entities/Property/Property.models';
import { PropertySelect } from 'entities/Property/components/PropertySelect';
import { PropertyRange } from 'entities/Property/components/PropertyRange';
import { IWorkspacePosition } from 'entities/Workspace/Workspace.models';
import { ICategoryPosition } from 'entities/Categories/Categories.models';

export const propertyListToPropertyTreeData = (propertyList?: IProperty[]) => {
  if (!propertyList) {
    return [];
  }

  const propertyTreeItemList = sortProperties.byDisplayName(
    propertyList.filter((property) => property.isCategory).map((property) => ({ ...property, name: property.displayName })),
  );

  return dataToTree<IProperty>(propertyTreeItemList);
};

const mapPropertyValues = (values?: IPropertyValue[]) => values?.map(({ value }) => value as string) || [];

const mapCascaderItemProperties = (propertyList: IProperty[]) => {
  return propertyList.map(({ id, name, unitOfMeasurement, isCategory, type }) => ({
    id,
    name,
    unitOfMeasurement,
    isCategory,
    type,
  }));
};

export const findCascaderItemProperty = (cascaderItem: IPropertyCascaderItem, propertyList?: IProperty[]) => {
  if (cascaderItem.rootPropertyId) {
    return propertyList?.find((property) => property.id === cascaderItem.rootPropertyId);
  }

  return propertyList?.find((property) => property.id === cascaderItem.propertyId);
};

export const findCascaderItemConditionalProperties = (
  cascaderItem: IPropertyCascaderItem,
  property?: IProperty,
  propertyList?: IProperty[],
) => {
  if (!property || !propertyList) {
    return [];
  }

  if (cascaderItem.rootPropertyId) {
    return mapCascaderItemProperties([
      propertyList.find((propertyItem) => propertyItem.id === cascaderItem.rootPropertyId) as IProperty,
    ]);
  }

  const conditionalProperties = propertyList.filter((propertyItem) => {
    return propertyItem.showCondition?.some((condition) => {
      return condition.propertyId === cascaderItem.propertyId && condition.values.includes(cascaderItem.value);
    });
  });

  if (property.isCategory) {
    const subcategory = propertyList.find((propertyItem) => {
      return propertyItem.parentId === cascaderItem.propertyId && propertyItem.displayName === cascaderItem.value;
    });

    if (subcategory) {
      conditionalProperties.push(subcategory);
    }
  }

  return mapCascaderItemProperties(sortProperties.byIsCategory(sortProperties.byName(conditionalProperties)));
};

const sortPropertyValuesByValue = (values?: IPropertyValue[]) => {
  if (!values) {
    return [];
  }

  return values.sort((a, b) => (a.value as string).localeCompare(b.value as string));
};

export const findCascaderItemOptions = (cascaderItem: IPropertyCascaderItem, propertyList?: IProperty[]) => {
  if (!propertyList) {
    return [];
  }

  const property = cascaderItem.rootPropertyId
    ? (propertyList.find((propertyItem) => propertyItem.id === cascaderItem.rootPropertyId) as IProperty)
    : (propertyList.find((propertyItem) => propertyItem.id === cascaderItem.propertyId) as IProperty);

  return sortPropertyValuesByValue(property.values).map(({ valueId, value }) => ({ valueId, value: value as string }));
};

export const findPropertyParentIds = (id: number, propertyList?: IProperty[]): number[] => {
  const property = propertyList?.find((propertyId) => propertyId.id === id);

  if (!property) {
    return [];
  }

  const parentIds: number[] = [];

  if (property.isCategory && property.parentId) {
    parentIds.push(property.parentId);
    parentIds.push(...findPropertyParentIds(property.parentId, propertyList));
  }

  if (!property.isCategory && property.showCondition) {
    property.showCondition.forEach((condition) => {
      parentIds.push(condition.propertyId);
      parentIds.push(...findPropertyParentIds(condition.propertyId, propertyList));
    });
  }

  return parentIds;
};

export const getCascaderItemPropertiesSearchOptions = (
  conditionalProperties: IPropertyCascaderItemProperty[],
  cascaderItemProperty?: IProperty,
  propertyList?: IProperty[],
) => {
  if (!cascaderItemProperty || !propertyList) {
    return [];
  }

  const excludedPropertyIds: number[] = [];

  [cascaderItemProperty, ...conditionalProperties].forEach((property) => {
    excludedPropertyIds.push(property.id);
    excludedPropertyIds.push(...findPropertyParentIds(property.id, propertyList));
  });

  return propertyList
    .filter(({ id, isCategory }) => !isCategory && !excludedPropertyIds.includes(id))
    .map(({ id, name }) => ({ label: name, value: id.toString() }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const getCascaderItemOptionsSearchOptions = (options: IPropertyValue[]) => {
  return options
    .map(({ valueId, value }) => ({ label: value as string, value: valueId.toString() }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const getCascaderItemAllProperties = (propertyList?: IProperty[]) => {
  if (!propertyList) {
    return [];
  }

  return mapCascaderItemProperties(sortProperties.byName(propertyList.filter((propertyItem) => !propertyItem.isCategory)));
};

export const getCascaderItemVisibleProperties = (
  propertyList: IPropertyCascaderItemProperty[],
  search: string,
  offset: number,
) => {
  return propertyList.filter((property) => property.name.toLowerCase().includes(search.toLowerCase())).slice(0, offset);
};

export const updatePropertyListState = {
  addProperty: (state: IPropertyListState, payload: IProperty) => ({
    ...state,
    data: [...state.data, payload],
  }),
  updateProperty: (state: IPropertyListState, payload: IProperty) => ({
    ...state,
    data: state.data.map((property) => (property.id === payload.id ? payload : property)),
  }),
  deleteProperty: (state: IPropertyListState, payload: number) => ({
    ...state,
    data: state.data.filter((property) => property.id !== payload),
  }),
  updatePropertyWithParent: (state: IPropertyListState, payload: { property: IProperty; parent: IProperty }) => ({
    ...state,
    data: state.data.map((property) => {
      if (property.id === payload.property.id) {
        return payload.property;
      }

      if (property.id === payload.parent.id) {
        return payload.parent;
      }

      return property;
    }),
  }),
  deletePropertyShowConditions: (state: IPropertyListState, payload: { propertyId: number; value: string }) => {
    return {
      ...state,
      data: state.data.map((property) => {
        return {
          ...property,
          showCondition: property.showCondition?.map((condition) => {
            if (condition.propertyId === payload.propertyId) {
              return { ...condition, values: condition.values.filter((value) => value !== payload.value) };
            }

            return condition;
          }),
        };
      }),
    };
  },
};

export const getPropertyPayload = {
  createSubcategory: (name: string, parentId?: number) => {
    return {
      name,
      displayName: name,
      parentId,
      isCategory: true,
      useInGoods: true,
      type: EPropertyType.List,
      presetType: EPresetType.MultipleValue,
    };
  },
  addPropertyValue: (value: string, property: IProperty) => ({
    id: property.id,
    values: [...mapPropertyValues(property.values), value],
  }),
  deletePropertyValue: (displayName: string, property: IProperty) => ({
    id: property.id,
    values: mapPropertyValues(property.values.filter(({ value }) => value !== displayName)),
  }),
  updatePropertyValue: (property: IProperty, oldValue: string, newValue: string) => {
    const newValues = property.values.map((value) => {
      if (value.value === oldValue) {
        return { ...value, value: newValue };
      }

      return value;
    });

    return {
      id: property.id,
      values: mapPropertyValues(newValues),
    };
  },
  addPropertyShowConditionValue: (property: IProperty, parentId: number, value: string) => {
    const prevShowCondition = property.showCondition;
    let newShowCondition = [];

    if (!prevShowCondition) {
      newShowCondition = [{ propertyId: parentId, values: [value] }];
    } else {
      const condition = prevShowCondition.find((conditionItem) => conditionItem.propertyId === parentId);

      if (condition) {
        newShowCondition = prevShowCondition.map((conditionItem) => {
          if (conditionItem.propertyId === parentId) {
            return { ...conditionItem, values: [...conditionItem.values, value] };
          }

          return conditionItem;
        });
      } else {
        newShowCondition = [...prevShowCondition, { propertyId: parentId, values: [value] }];
      }
    }

    return { id: property.id, showCondition: newShowCondition };
  },
  deletePropertyShowConditionValue: (property: IProperty, parentId: number, parentValue: string) => {
    return {
      id: property.id,
      showCondition: property.showCondition
        .map((condition) => {
          if (condition.propertyId === parentId) {
            return { ...condition, values: condition.values.filter((value) => value !== parentValue) };
          }

          return condition;
        })
        .filter((condition) => !!condition.values.length),
    };
  },
  createPropertyWithShowCondition: (name: string, parentId: number, value: string) => ({
    name,
    displayName: name,
    type: EPropertyType.List,
    presetType: EPresetType.MultipleValue,
    showCondition: [{ propertyId: parentId, values: [value] }],
  }),
};

export const createCascaderItem = {
  rootItem: (property: IProperty) => ({
    id: getId(),
    type: EPropertyCascaderItemType.Properties,
    propertyId: property.parentId,
    rootPropertyId: !property.parentId ? property.id : undefined,
    value: property.displayName,
  }),
  itemWithOptions: (property: IProperty) => ({
    id: getId(),
    type: EPropertyCascaderItemType.Options,
    propertyId: property.id,
    value: property.isCategory ? `${property.displayName} | подкатегории` : `Список значений для ${property.displayName}`,
    propertyType: property.type,
    isCategory: property.isCategory,
  }),
  itemWithProperties: (propertyId: number, value: string) => ({
    id: getId(),
    type: EPropertyCascaderItemType.Properties,
    propertyId,
    value,
  }),
  itemWithAllProperties: () => ({
    id: getId(),
    type: EPropertyCascaderItemType.All,
    value: 'Все характеристики',
  }),
};

export const updatePropertyCascaderState = {
  setCascaderItems: (state: IPropertyCascaderState, payload: IPropertyCascaderItem[]) => ({ ...state, data: payload }),
  addCascaderItem: (state: IPropertyCascaderState, payload: IPropertyCascaderItem) => ({
    ...state,
    data: [...state.data, payload],
  }),
  filterCascaderItems: (state: IPropertyCascaderState, payload: string) => {
    const cascaderItemIndex = state.data.findIndex((cascaderItem) => cascaderItem.id === payload);

    return {
      ...state,
      data: state.data
        .filter((_, index) => index <= cascaderItemIndex)
        .map((cascaderItem) => {
          if (cascaderItem.id === payload) {
            return {
              ...cascaderItem,
              selectedPropertyId: null,
            };
          }

          return cascaderItem;
        }),
      selectedPropertyId: null,
    };
  },
  changeCascaderItemSelectedPropertyId: (state: IPropertyCascaderState, payload: IPropertyCascaderItemSelectPropertyPayload) => {
    const cascaderItemIndex = state.data.findIndex((cascaderItem) => cascaderItem.id === payload.cascaderItemId);

    return {
      ...state,
      data: state.data
        .filter((_, index) => index <= cascaderItemIndex)
        .map((cascaderItem) => {
          if (cascaderItem.id === payload.cascaderItemId) {
            return {
              ...cascaderItem,
              selectedPropertyId: payload.propertyId,
            };
          }

          return cascaderItem;
        }),
      selectedPropertyId: payload.propertyId,
    };
  },
  changeCascaderItemSelectedOptionId: (state: IPropertyCascaderState, payload: { valueId: number; cascaderItemId: string }) => {
    const cascaderItemIndex = state.data.findIndex((cascaderItem) => cascaderItem.id === payload.cascaderItemId);

    return {
      ...state,
      data: state.data
        .filter((_, index) => index <= cascaderItemIndex)
        .map((cascaderItem) => {
          if (cascaderItem.id === payload.cascaderItemId) {
            return {
              ...cascaderItem,
              selectedOptionId: payload.valueId,
            };
          }

          return cascaderItem;
        }),
      selectedPropertyId: null,
    };
  },
  setCascaderItemError: (state: IPropertyCascaderState, payload: { error: string | null; cascaderItemId: string }) => ({
    ...state,
    data: state.data.map((cascaderItem) => {
      if (cascaderItem.id === payload.cascaderItemId) {
        return { ...cascaderItem, error: payload.error };
      }

      return cascaderItem;
    }),
  }),
  setCascaderSelectedPropertyId: (state: IPropertyCascaderState, payload: number | null) => ({
    ...state,
    selectedPropertyId: payload,
  }),
  setCascaderError: (state: IPropertyCascaderState, payload: string | null) => ({ ...state, error: payload }),
  updateCascaderItemWithOptions: (state: IPropertyCascaderState, payload: IProperty) => {
    return {
      ...state,
      data: state.data.map((cascaderItem) => {
        if (cascaderItem.propertyId === payload.id && cascaderItem.type === EPropertyCascaderItemType.Options) {
          return {
            ...cascaderItem,
            value: payload.isCategory ? `${payload.displayName} | подкатегории` : `Список значений для ${payload.displayName}`,
            propertyType: payload.type,
          };
        }

        return cascaderItem;
      }),
    };
  },
  updateCascaderItemWithProperties: (state: IPropertyCascaderState, payload: { property: IProperty; parent: IProperty }) => {
    const newParentOption = payload.parent.values.find((value) => value.value === payload.property.displayName);

    return {
      ...state,
      data: state.data.map((cascaderItem) => {
        if (cascaderItem.propertyId === payload.property.parentId && cascaderItem.type === EPropertyCascaderItemType.Options) {
          return {
            ...cascaderItem,
            selectedOptionId: newParentOption?.valueId,
          };
        }

        if (cascaderItem.propertyId === payload.property.parentId && cascaderItem.type === EPropertyCascaderItemType.Properties) {
          return {
            ...cascaderItem,
            value: payload.property.displayName,
          };
        }

        if (cascaderItem.propertyId === payload.property.id && cascaderItem.type === EPropertyCascaderItemType.Options) {
          return {
            ...cascaderItem,
            value: `${payload.property.displayName} | подкатегории`,
          };
        }

        return cascaderItem;
      }),
    };
  },
  deleteCascaderItemWithProperties: (state: IPropertyCascaderState, payload: IProperty) => {
    const cascaderItemIndex = state.data.findIndex((cascaderItem) => {
      return (
        cascaderItem.propertyId === payload.parentId &&
        cascaderItem.type === EPropertyCascaderItemType.Properties &&
        cascaderItem.value === payload.displayName
      );
    });
    const cascaderItemExists = cascaderItemIndex > 0;

    return {
      ...state,
      data: cascaderItemExists ? state.data.filter((_, index) => index < cascaderItemIndex) : state.data,
      selectedPropertyId: cascaderItemExists ? null : state.selectedPropertyId,
      error: cascaderItemExists ? null : state.error,
    };
  },
};

export const renderPropertyFormField = (property: IProperty) => {
  const { id, isCategory, isRequiredForPresets, hidden } = property;
  const { isSingleValue, isMultipleValue, isCheckbox, isRange } = getPropertyPresetType(property);

  if (isCategory || hidden) {
    return null;
  }

  if (isSingleValue) {
    return (
      <PropertySelect
        key={id}
        property={property}
        fieldClassName="mb-32"
        rules={isRequiredForPresets ? [rules.required()] : undefined}
      />
    );
  }

  if (isMultipleValue || isCheckbox) {
    return (
      <PropertySelect
        key={id}
        property={property}
        fieldClassName="mb-32"
        mode="multiple"
        rules={isRequiredForPresets ? [rules.required()] : undefined}
      />
    );
  }

  if (isRange) {
    return <PropertyRange key={id} property={property} fieldClassName="mb-32" />;
  }

  return null;
};

export const getPropertyPresetType = (property: IProperty) => {
  return {
    isSingleValue: property.presetType === EPresetType.SingleValue,
    isMultipleValue: property.presetType === EPresetType.MultipleValue,
    isRange: property.presetType === EPresetType.Range,
    isCheckbox: property.presetType === EPresetType.Checkbox,
  };
};

export const setPropertyListItemHidden = (propertyList: IProperty[], index = 0): IProperty[] => {
  if (index === propertyList.length) {
    return propertyList;
  }

  const mappedPropertyList = propertyList.map((property, _, array) => {
    const hidden =
      property.isCategory || !property.showCondition
        ? false
        : property.showCondition?.every((condition) => {
            const parent = array.find((item) => item.id === condition.propertyId);

            return !parent?.result?.some((item) => condition.values.includes(item));
          });

    return {
      ...property,
      result: hidden ? [] : property.result,
      hidden,
    };
  });

  return setPropertyListItemHidden(mappedPropertyList, index + 1);
};

export const getPropertyRangeResult = (min?: string | number | null, max?: string | number | null) => {
  const result = [];
  const minValue = min ? min.toString() : undefined;
  const maxValue = max ? max.toString() : undefined;

  if (minValue && maxValue) {
    result.push(minValue, maxValue);
  }

  if (!minValue && maxValue) {
    result.push('', maxValue);
  }

  if (minValue && !maxValue) {
    result.push(minValue);
  }

  return result;
};

export const propertyRangeResultToRangeValue = (result?: string[]) => {
  return {
    from: result?.[0]?.length ? stringToNumber(result?.[0]) : undefined,
    to: result?.[1]?.length ? stringToNumber(result?.[1]) : undefined,
  };
};

export const getSearchParamsUrlFromPropertyList = (propertyList: IProperty[]) => {
  return propertyList
    .filter(({ isCategory, result }) => !isCategory && !!result?.length)
    .map(({ id, result }) => {
      const encodedResult = result?.map((value) => `${encodeURIComponent(JSON.stringify(value))}`);

      return `${id}=[${encodedResult}]`;
    })
    .join('&');
};

export const filterNotApplicableProperties = (propertyList: IProperty[]) => {
  return propertyList.filter(({ presetType }) => presetType !== EPresetType.NotApplicable);
};

export const setPropertyListResult = {
  forNewWorkspacePosition: (propertyList: IProperty[], positions: ICategoryPosition[]) => {
    return propertyList.map((property) => {
      const propertyFromPosition = positions.find((position) => position.propertyId === property.id);

      if (propertyFromPosition) {
        const { isRange } = getPropertyPresetType(property);

        return {
          ...property,
          result: isRange
            ? getPropertyRangeResult(propertyFromPosition.range?.from, propertyFromPosition.range?.to)
            : propertyFromPosition.values,
        };
      } else {
        return { ...property, result: [] };
      }
    });
  },
  forEditWorkspacePosition: (propertyList: IProperty[], position: IWorkspacePosition) => {
    return propertyList.map((property) => {
      const propertyFromPosition = position.properties.find((item) => item.propertyId === property.id);

      if (propertyFromPosition) {
        const { isRange } = getPropertyPresetType(property);

        return {
          ...property,
          result: isRange
            ? getPropertyRangeResult(propertyFromPosition.range?.from, propertyFromPosition.range?.to)
            : propertyFromPosition.values,
        };
      } else {
        return { ...property, result: [] };
      }
    });
  },
  forCatalogGoodsListFilter: (propertyList: IProperty[], positions?: ICategoryPosition[]) =>
    propertyList.map((property) => {
      const propertyFromPosition = positions?.find((position) => property.isCategory && position.propertyId === property.id);
      const params = new URLSearchParams(window.location.search);
      const result = params.get(property.id.toString());

      if (result) {
        return { ...property, result: JSON.parse(result) };
      } else if (propertyFromPosition) {
        const { isRange } = getPropertyPresetType(property);

        return {
          ...property,
          result: isRange
            ? getPropertyRangeResult(propertyFromPosition.range?.from, propertyFromPosition.range?.to)
            : propertyFromPosition.values,
        };
      } else {
        return { ...property, result: [] };
      }
    }),
};

export const mapPropertyListForViewToFormValues = (propertyList: IProperty[]) => {
  return propertyList
    .map((property) => {
      const { isSingleValue, isRange } = getPropertyPresetType(property);

      if (property.result?.length) {
        if (isSingleValue) {
          return [numberToString(property.id), property.result[0]];
        }

        if (isRange) {
          return [numberToString(property.id), [propertyRangeResultToRangeValue(property.result)]];
        }

        return [numberToString(property.id), property.result];
      }

      return [];
    })
    .filter((item) => item.length);
};
