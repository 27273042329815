import axios, { AxiosError, AxiosResponse } from 'axios';
import { EErrorStatus, EStorageKey } from 'common/const/axios.enum';
import { EMessage } from 'common/const/message.enum';
import { IAxiosResponseData } from 'common/models';
import { showErrorMessage } from 'common/helpers/message.helper';
import store from 'app/store';
import { IToken } from 'entities/Auth/Auth.models';
import { clearCreds, saveCreds } from 'entities/Auth/Auth.helper';
import { authTransport } from 'entities/Auth/Auth.transport';

export const getStorageItem = <T>(key: EStorageKey): T | null => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

export const saveStorageItem = (key: EStorageKey, value: IToken | number): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeStorageItem = (key: EStorageKey): void => {
  localStorage.removeItem(key);
};

export const initAxios = () => {
  axios.defaults.baseURL = `/api`;
  axios.interceptors.request.use(async (config) => {
    const creds = getStorageItem<IToken>(EStorageKey.Creds);
    const xUserId = getStorageItem<string>(EStorageKey.XUserId);
    const xBasketId = getStorageItem<string>(EStorageKey.XBasketId);

    if (creds) {
      config.headers.Authorization = `Bearer ${creds.access.token}`;
    }

    if (xUserId) {
      config.headers['x-user-id'] = xUserId;
    }

    if (
      xBasketId &&
      (config.url?.includes('catalog') ||
        config.url?.includes('category') ||
        config.url?.includes('property/by-category') ||
        config.url?.includes('good') ||
        config.url?.includes('search') ||
        config.url?.includes('basket'))
    ) {
      config.headers['x-basket-id'] = xBasketId;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response: AxiosResponse) => response.data,
    async (error) => {
      const { config, response } = error;

      if (response) {
        if (config.url === 'auth' && config.method === 'put') {
          clearCreds(store.dispatch);
          return Promise.reject(error);
        } else if (config.url !== 'auth' && response.status === EErrorStatus.Unauthorized) {
          const creds = getStorageItem<IToken>(EStorageKey.Creds);

          if (creds?.refresh.token) {
            const refreshResponse = await authTransport.refreshToken(creds.refresh.token);

            if (refreshResponse) {
              await saveCreds(store.dispatch, refreshResponse);
              config.headers.Authorization = `Bearer ${creds.access?.token}`;
              return axios(config);
            } else {
              clearCreds(store.dispatch);
              return Promise.reject(error);
            }
          } else {
            clearCreds(store.dispatch);
            return Promise.reject(error);
          }
        } else {
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    },
  );
};

export const axiosErrorHandler = (error: unknown, setError?: (payload: string) => void) => {
  const response = (error as AxiosError).response as AxiosResponse<IAxiosResponseData | undefined>;

  if (response) {
    const { data, status, statusText } = response;

    if (data) {
      if (setError && status !== EErrorStatus.InternalServerError) {
        setError(data.message);
      } else {
        showErrorMessage(data.message || data.error);
      }
    } else {
      showErrorMessage(statusText);
    }
  } else {
    showErrorMessage(EMessage.UnexpectedError);
  }
};
