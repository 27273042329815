export enum EColor {
  Gray = 'gray',
  Green = 'green',
  Orange = 'orange',
  Blue = 'blue',
  // Redesign
  BrightGreen = 'bright-green',
  LightGrey = 'light-grey',
  PalePurple = 'pale-purple',
  Red = 'red',
  PaleBlue = 'pale-blue',
  PaleGreen = 'pale-green',
  DarkGrey = 'dark-grey',
}
