import React, { FC, ReactNode, useMemo } from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { CountBadge } from 'common/components/CountBadge';
import { ESearchParams } from 'common/const/searchParams.enum';
import { EInventoryListMenuTab } from 'common/const/inventory.enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { RootState } from 'app/store';
import { getInventoryListFilterOptions } from 'entities/Inventory/Inventory.helper';
import { WriteOffIncomingInventoryActionList } from 'entities/Inventory/components/WriteOff/WriteOffIncomingInventoryActionList';
import { IUserInventoryStatistics } from 'entities/User/User.models';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  statistics: IUserInventoryStatistics;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const { header, navigation, statistics, inventoryActionListSubdivisions, inventoryActionListUsers } = props;

  const { getSearchParam, setSearchParam } = useSearchParamsHook();

  const { writeOffsCount } = statistics;
  const tab = getSearchParam(ESearchParams.Tab);
  const subdivisionOptions = getInventoryListFilterOptions.subdivision(inventoryActionListSubdivisions);
  const userOptions = getInventoryListFilterOptions.user(inventoryActionListUsers);
  const menu = useMemo(() => {
    return (
      <Menu
        items={[
          {
            key: EInventoryListMenuTab.Incoming,
            label: (
              <>
                Входящие запросы
                <CountBadge count={writeOffsCount} />
              </>
            ),
          },
        ]}
        selectedKeys={[tab === null ? EInventoryListMenuTab.Incoming : tab]}
        onClick={({ key }) => setSearchParam(ESearchParams.Tab, key)}
        mode="horizontal"
      />
    );
  }, [tab, statistics]);

  return (
    <WriteOffIncomingInventoryActionList
      header={header}
      navigation={navigation}
      menu={menu}
      subdivisionOptions={subdivisionOptions}
      userOptions={userOptions}
    />
  );
};

const mapState = (state: RootState) => ({
  inventoryActionListSubdivisions: state.inventoryActionListState.subdivisionList,
  inventoryActionListUsers: state.inventoryActionListState.userList,
});

export const WriteOffInventoryActionList = connect(mapState)(Component);
