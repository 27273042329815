import React, { FC, useEffect, useMemo, useState } from 'react';
import { Menu, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { ESettingsMenuTab, ESettingsTab } from 'common/const/settings.enum';
import { ERoute } from 'common/const/route.enum';
import { RootDispatch, RootState } from 'app/store';
import { AccountSettingsName } from 'entities/Settings/components/AccountSettings/AccountSettingsName';
import { AccountContractsSettings } from 'entities/Settings/components/AccountSettings/Contracts/AccountContractsSettings';
import { AccountUserListSettings } from 'entities/Settings/components/AccountSettings/UserList/AccountUserListSettings';
import { AccountLegalEntityListSettings } from 'entities/Settings/components/AccountSettings/LegalEntityList/AccountLegalEntityListSettings';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ currentUser, account, accountLoading, getAccountById, updateAccount, updateAccountName }) => {
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const { menu } = useParams();
  const navigate = useNavigate();

  const accountId = currentUser?.account?.id;

  const header = useMemo(() => {
    return (
      <>
        <div className="text-body color-dark-grey mb-20">Настройки аккаунта</div>

        <AccountSettingsName account={account} loading={accountLoading} onAccountUpdate={updateAccountName} />

        <Menu
          items={[
            { key: ESettingsMenuTab.Contracts, label: 'Контракты' },
            { key: ESettingsMenuTab.UserList, label: 'Пользователи' },
            { key: ESettingsMenuTab.LegalEntityList, label: 'Юридические лица' },
          ]}
          selectedKeys={[!menu ? ESettingsMenuTab.Contracts : menu]}
          onClick={({ key }) => navigate(`${ERoute.Settings}/${ESettingsTab.Account}/${key}`)}
          mode="horizontal"
        />
      </>
    );
  }, [account, accountLoading, menu]);

  const renderContent = () => {
    switch (menu) {
      case ESettingsMenuTab.UserList: {
        return <AccountUserListSettings header={header} account={account} />;
      }
      case ESettingsMenuTab.LegalEntityList: {
        return <AccountLegalEntityListSettings header={header} />;
      }
      default: {
        return <AccountContractsSettings header={header} account={account} onAccountUpdate={updateAccount} />;
      }
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (accountId) {
        await getAccountById(accountId);
      }

      setFetchLoading(false);
    };

    fetch();
  }, [accountId]);

  if (fetchLoading) {
    return (
      <div className="account-settings">
        <Spin indicator={<SpinIndicator />} />
      </div>
    );
  }

  return <div className="account-settings">{renderContent()}</div>;
};

const mapState = (state: RootState) => ({
  currentUser: state.userState.currentUser,
  account: state.accountState.data,
  accountLoading: state.accountState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getAccountById: dispatch.accountState.getAccountById,
  updateAccount: dispatch.accountState.updateAccount,
  updateAccountName: dispatch.accountState.updateAccountName,
});

export const AccountSettings = connect(mapState, mapDispatch)(Component);
